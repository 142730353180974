import { DatePipe } from "@angular/common";
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { PoolBuilderService } from "@sharedservices/BackServices/ComTrak/PoolBuilder/poolBuilder.service";
import { CustomGridColumns } from "@app/components/datatable/datatable.component";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { PoolStatus } from "@sharedmodels/DMCREnums/DMCREnums";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-refresh-pool-details",
  templateUrl: "./refreshPoolDetails.component.html",
  styleUrls: ["./refreshPoolDetails.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class refreshPoolDetailsComponent implements OnInit {
  myData: any;
  totalPatientsCount: number = 0;
  adjustedPatientsCount: number = 0;
  noOfPatientsToInsertCount: number = 0;
  noOfPatientsToReleaseCount: number = 0;
  overlappedPools: Array<any> = [];
  stealedPatientIds: any[] = [];
  overlappedPoolsColumnDefinition: CustomGridColumns[];
  popoverStatus: { [key: number]: NgbPopover } = {};
  errorMessage = "";

  constructor(
    public dialogRef: MatDialogRef<refreshPoolDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    public poolBuilderService: PoolBuilderService,
    public _Global: GlobalfunctionService,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.totalPatientsCount = this.data.totalPatients;
    this.overlappedPools = this.data.overlappingPools;
    this.noOfPatientsToInsertCount = this.data.noOfPatientsToInsert
    this.noOfPatientsToReleaseCount = this.data.noOfPatientsToRelease

    // Load columns
    this.gridConfig()
  }

  gridConfig() {
    this.overlappedPoolsColumnDefinition = [
      {
        header: "List Name",
        key: "name",
        visible: true,
        type: "string",
      },
      {
        header: "Overlapping Patients",
        key: "overlappingPatients",
        visible: true,
        type: "string",
      },
      {
        header: "Total Count",
        key: "totalCount",
        visible: true,
        type: "string",
      },
      {
        header: "Actions",
        width: 240,
        key: "actions",
        visible: true,
        type: "string",
      },
    ];
  }

  getStatus(val: number) {
    return PoolStatus[val];
  }

  onReleasePool(pool: any) {
    pool.isReleased = !pool.isReleased;

    if (pool.isReleased) {
      if (pool.releasedCount) {
        this.adjustedPatientsCount -= pool.releasedCount;
      }
      this.adjustedPatientsCount += pool.overlappingCount;
      if (!!this.stealedPatientIds.find((x) => x.poolId === pool.poolId)) {
        this.stealedPatientIds = this.stealedPatientIds.map((x) => {
          if (x.poolId === pool.poolId) {
            x.overlappinPatientIds = pool.patientIds;
          }
          return x;
        });
      } else {
        this.stealedPatientIds.push({
          poolId: pool.poolId,
          overlappinPatientIds: pool.patientIds,
        });
      }
      pool.releasedCount = pool.overlappingCount;
    } else {
      this.adjustedPatientsCount =
        this.adjustedPatientsCount - pool.overlappingCount;
      this.stealedPatientIds = this.stealedPatientIds.filter(
        (x) => x.poolId !== pool.poolId
      );
      pool.releasedCount -= pool.overlappingCount;
    }
  }

  onReleasePartial(pool: any, partialCount: any) {
    if (!partialCount) {
      this.validatePartialCount(partialCount, pool);
      return;
    }

    partialCount = parseInt(partialCount);

    if (typeof partialCount !== "number" || partialCount < 0 || partialCount > pool.overlappingCount) {
      this.validatePartialCount(partialCount, pool);
      return;
    }

    if (pool.isReleased) {
      this.adjustedPatientsCount -= pool.overlappingCount;
    } else if (pool.releasedCount) {
      this.adjustedPatientsCount -= pool.releasedCount;
    }

    pool.releasedCount = partialCount;

    if (partialCount === pool.overlappingCount) {
      pool.isReleased = true;
    }

    this.adjustedPatientsCount += partialCount;

    const patientIds = [...pool.patientIds].slice(0, partialCount);

    if (!!this.stealedPatientIds.find((x) => x.poolId === pool.poolId)) {
      this.stealedPatientIds = this.stealedPatientIds.map((x) => {
        if (x.poolId === pool.poolId) {
          x.overlappinPatientIds = patientIds;
        }
        return x;
      });
    } else {
      this.stealedPatientIds.push({
        poolId: pool.poolId,
        overlappinPatientIds: patientIds,
      });
    }

    this.closePopover(pool.poolId);
  }

  onSubmit() {
    this.dialogRef.close(this.stealedPatientIds);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openPopover(poolId: number, popover: NgbPopover) {
    this.errorMessage = "";
    // Close any other open popovers
    Object.keys(this.popoverStatus).forEach((id) => {
      if (this.popoverStatus[id]) {
        this.popoverStatus[id].close();
      }
    });

    // Open the popover for the given pool id
    this.popoverStatus[poolId] = popover;
    popover.open();
  }

  // Method to close the popover based on pool.id
  closePopover(poolId: number) {
    this.errorMessage = "";
    const popover = this.popoverStatus[poolId];
    if (popover) {
      popover.close();
      this.popoverStatus[poolId] = null;
    }
  }

  validatePartialCount(partialCount: string, pool: any){
    if (!partialCount) {
      this.errorMessage = "Please enter a number to release.";
      return;
    } else if (parseInt(partialCount) < 0) {
      this.errorMessage = "Please enter a value over 0.";
      return;
    } else if (parseInt(partialCount) > pool.overlappingCount) {
      this.errorMessage = "Please enter a value under " + pool.overlappingCount + ".";
      return;
    } else {
      this.errorMessage = "";
    }
  }

  onPartialCountChange(event: Event, pool: any) {
    const partialCount = (event.target as HTMLInputElement).value;
    this.validatePartialCount(partialCount, pool);
  }
}
