export const environment = {
  production: false,
  staging: true,
  APIEndpoint : 'https://dev.be.comtrak.dmclinical.com/api/v1/' ,
  APIEndpointv2 : 'https://dev.be.comtrak.dmclinical.com/api/v2.0/' ,
  APIPatientMgmtEndpoint : 'https://apim-patient-module-dev.azure-api.net/api/' ,
  APIURLBase : 'https://dev.be.comtrak.dmclinical.com/' ,
  ASSET_URL: 'https://pegasus-staging-cdn.s3.us-east-2.amazonaws.com/',
  nodeSocketUrl: 'https://commodule.dev.dmclinical.com',
  TWILIO_FUNCTION_EP: "https://burgundy-cockroach-5100.twil.io/",
  COMMODEndPointv1 : 'https://commodule.dev.dmclinical.com/',
  PatientPaymentURL: 'https://dev-dots-patient-payment-d3dfd2g0bdhwhpe0.centralus-01.azurewebsites.net/',
  sentry_URL:'https://a885e2ed216a4825a7884dbd1e7b799a@o562586.ingest.sentry.io/5708707',
  tracking_code:'G-2SEKHL5K6V',
  hotjarSiteId:2920367,
  appInsightsInstrumentationKey:"52c5ade3-c46d-48c5-bfee-8df158692c08",
  comtrakUrl: 'https://comtrak.dev.dmclinical.com', // Only define if you want to redirect users from Beta to Prod
  env: 'dev',
  appVersion: 'V 1.0.19 (091820241000CST)'
};
