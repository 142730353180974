import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { DateRangePickerModule   } from '@syncfusion/ej2-angular-calendars';


// This Module's Components
import { DaterangepickerComponent } from './daterangepicker.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbPopoverModule,
        NgbDatepickerModule,
        DateRangePickerModule
    ],
    declarations: [
        DaterangepickerComponent,
    ],
    exports: [
        DaterangepickerComponent,
    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class DateRangepickerModule {

}
