import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs'
import { RecruiterService } from '@sharedservices/BackServices/ComTrak/Communication/recruiter.service';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-slotaudit',
    templateUrl: './slotaudit.component.html',
    styleUrls: ['./slotaudit.component.scss'],
})
export class SlotAuditComponent implements OnInit {

    toastOptions: Partial<IndividualConfig> = {
        timeOut: 3000,
        positionClass: 'toast-top-right'    
    };
    subs = new Subscription();
    dateFilter = new FormControl();
    columnDefinition = [
        'statusUpdatedOn',
        'statusUpdatedTime',
        'studyName',
        'date',
        'time',
        'status',
        'name'
    ];
    auditData = [];
    dataSource: MatTableDataSource<any>;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private toastService: ToastrService,
        public _Global: GlobalfunctionService,
        public spinner: NgxSpinnerService,
        private recruiterService: RecruiterService,
        private datePipe: DatePipe,
        public dialogRef: MatDialogRef<SlotAuditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit(): void {
        this.dateFilter.valueChanges.pipe(
            distinctUntilChanged()
        ).subscribe(v => {
            this.getAuditData(0, 20);
        })
    }

    onKeyDown(event: KeyboardEvent) {
        var keyCode = event.keyCode || event.which;
        if (
          (keyCode >= 48 && keyCode <= 57) || // Numbers 0-9
          (keyCode >= 96 && keyCode <= 105) || // Numpad numbers 0-9
          (event.key === 'A') ||
          (event.key === 'M') ||
          (event.key === 'P') ||
          (event.key === ':') ||
          event.key === '/' || // / (forward slash)
          keyCode === 8 || // Backspace
          keyCode === 46 || // Delete
          keyCode === 37 || // Left arrow
          keyCode === 39 || // Right arrow
          keyCode === 32 || // Space key
          keyCode === 9 // Tab key
        ) {
          return true;
        }
        // Prevent the default action for other keys
        event.preventDefault();
    }

    onFilterDateRangeChange(dateRange: { startDate: Date, endDate: Date }): void {
        if (dateRange.startDate && dateRange.endDate) {
          let start = this.datePipe.transform(dateRange.startDate, "yyyy-MM-dd");
          let end = this.datePipe.transform(dateRange.endDate, "yyyy-MM-dd");
          this.dateFilter.setValue(start + ',' + end);
        } else {
          this.dateFilter.reset();
        }
    }

    getAuditData(pageNo: number, pageSize: number): void {
        this.spinner.show();
        this._Global.IsReady = false;

        let params = {
            siteId: this.data.site.siteId,
            page: pageNo + 1,
            pageSize: pageSize,
            date: this.dateFilter.value || null
        }

        const getData = this.recruiterService.getAuditTrailData(params).subscribe({
            next: (res) => {
                this.spinner.hide();
                this._Global.IsReady = true;
                if (res.status) {
                    this.auditData = res.data;
                    this.dataSource = new MatTableDataSource(res.data);
                    this.initializePaginator(res.data);
                    this.paginator.length = res.totalCount;
                    this.dataSource.sort = this.sort;
                } else {
                    this.toastService.error('', res.message, this.toastOptions);
                }
            },
            error: (err) => {
                this.spinner.hide();
                this._Global.IsReady = true;
                this.toastService.error('', err.message, this.toastOptions);
            }
        });
        this.subs.add(getData);
    }

    initializePaginator(data: []): void {
        this.dataSource = new MatTableDataSource(data);
        this.paginator.page.subscribe((event) => {
            this.getAuditData(event.pageIndex, event.pageSize);
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}