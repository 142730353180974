import { SiteService } from './site.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';

import { Study } from '@sharedmodels/BackModels/ComTrak/Setups/Study';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudyService {
  public isCallApi = new Subject<boolean>();
  public isAddCrioStudy = new Subject<boolean>();
  public _studies: Study[];
  public _studiesArray: Study[];
  public _studiesActiveArray: Study[];
  public _activeStudiesArray: Study[];
  public _studyBYID: Study[];
  public arraylength: number;
  public _activeStudies: Study[];
  public _StudyStatuses: any[] = [];
  _CRIOStudiesNotInStudy: any[] = [];

  constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private _GA: GoogleAnalyticsService, private spinner: NgxSpinnerService,
    public siteService: SiteService) { }

  LoadStudies(searchString: string) {
    try {
      // this.spinner.show()
      // this._Global.IsReady = false;
      this.isCallApi.next(false)
      this._http.get(myGlobals._apiPath + 'study?searchString=' + searchString, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._studies = <Study[]>data;
          this._studiesArray = <Study[]>data;
          this._studiesActiveArray = this._studiesArray.filter(o => o.IsActive == true).sort((a, b) => a.Name.localeCompare(b.Name));
          this.arraylength = this._studies.length
          // this.spinner.hide()
          // this._Global.IsReady = true;
          this.isCallApi.next(true)
        },
          error => {
            this._Global.handleError(error);
            // this.spinner.hide()
            // this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      // this._Global.IsReady = true;
      // this.spinner.hide()
      this.isCallApi.next(false)
    }
  }

  GetStudies(searchString: string): Study[] {
    if (this._studies !== undefined && this._studies.length !== null) {
      return this._studies;
    } else {
      this.LoadStudies(searchString);
    }
  }

  async LoadActiveStudies() {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
  
      const data = await this._http.get(myGlobals._apiPath + 'study?type=ActiveStudies', { headers: this._Global.httpOptions }).toPromise();
  
      this._activeStudies = <Study[]>data;
      this._activeStudiesArray = <Study[]>data;
      this.siteService._sitesStudy = this._activeStudies;
  
      this.spinner.hide();
      this._Global.IsReady = true;
    } catch (error) {
      this._Global.handleError(error);
      this.spinner.hide();
      this._Global.IsReady = true;
    }
  }  

  GetStudyStatuses() {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.get(myGlobals._apiPath + 'study?type=GetStudyStatuses', { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._StudyStatuses = <any[]>data;
          this.spinner.hide()
          this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

  async GetActiveStudies(): Promise<Study[]> {
    if (this._activeStudies !== undefined && this._activeStudies.length !== null) {
      return this._activeStudies;
    } else {
      await this.LoadActiveStudies();
      return this._activeStudies;
    }
  } 

  GetStudyByID(studyId: number): Observable<Study> {
    try {
      return this._http.get(myGlobals._apiPath + 'study?studyId=' + studyId, { headers: this._Global.httpOptions })
    } catch (x) {
    }
  }
  GetStudiesByPatientLocation(patientId: number, screenName: string = ''): Observable<Study> {
    try {
      return this._http.get(myGlobals._apiPath + 'study?type=StudiesByPatientLocation&patientId=' + patientId + '&screenName=' + screenName, { headers: this._Global.httpOptions })
    } catch (x) {
    }
  }

  SaveStudy(body: Study) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.post(myGlobals._apiPath + 'Study/Save', body, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this.isCallApi.next(true)
          this._Global.showSuccessMsg("Study Saved Successfully")
          this.spinner.hide()
          this._Global.IsReady = true;
          this.LoadStudies('')
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }

  EditStudy(siteID: number, body: Study) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.put(myGlobals._apiPath + 'Study/Edit/' + siteID, body, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this.isCallApi.next(true)
          this._Global.showSuccessMsg("Study Updated Successfully")
          this.spinner.hide()
          this._Global.IsReady = true;
          this.LoadStudies('')
          this.LoadActiveStudies()
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }

  SaveAndEditStudy(siteId: number, body: Study, isEdit: boolean) {
    if (isEdit) {
      this.EditStudy(siteId, body)
    }
    else {
      this.SaveStudy(body)
    }
  }

  getCRIOStudiesNotInStudy() {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.get(myGlobals._apiPath + 'study?type=getCRIOStudiesNotInStudy', { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._CRIOStudiesNotInStudy = <any[]>data;
          this.spinner.hide()
          this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

  CreateNewCRIOStudy(body: any) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.post(myGlobals._apiPath + 'Study/CreateNewCRIOStudy', body, { headers: this._Global.httpOptions })
        .subscribe((data: any) => {
          if (data && data.isErr == "1") {
            this._Global.showErrorMsg(data.Msg)
          }
          else {
            this.isAddCrioStudy.next(true)
            this.getCRIOStudiesNotInStudy()
            this._Global.showSuccessMsg(data.Msg)
          }
          this.spinner.hide()
          this._Global.IsReady = true;
        },
          error => {
            this.isAddCrioStudy.next(false)
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }
}
