import { SocialUser } from './../../../Shared/Models/BackModels/ComTrak/Security/SocialUser';
import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { Router, NavigationEnd, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { DatePipe, Location } from '@angular/common';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { SocialService } from 'ngx-social-button';
import { RoutesService } from '@sharedservices/BackServices/ComTrak/Security/routes.service';
import Hotjar from '@hotjar/browser';
import { environment } from 'environments/environment';
import { RecruiterService } from '@sharedservices/BackServices/ComTrak/Recruiters/recruiter.service';
import { MatDialog, MatDialogRef, MatMenuTrigger } from '@angular/material';
import { CreatememoComponent } from '@app/pages/creatememo/creatememo.component';
import { ViewMemoComponent } from '@app/pages/view-memo/view-memo.component';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotitificationsService } from '@sharedservices/BackServices/ComTrak/Communication/notifications.service';
import { HttpParams } from '@angular/common/http';
import { PrescreenService } from '@sharedservices/BackServices/ComTrak/Communication/prescreen.service';
import { SocketHandlerService } from '@sharedservices/FrontServices/socket-handler.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';
import { StatusService } from '@sharedservices/BackServices/ComTrak/Setups/status.service';
import { StudyService } from '@sharedservices/BackServices/ComTrak/Setups/study.service';
import { CallListType } from '@sharedmodels/DMCREnums/DMCREnums';
import { EmailConnectDialogComponent } from '@app/pages/email-connect/email-connect.component';
import { TwilioService } from '@sharedservices/FrontServices/twilio.service';


const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare var $: any;
@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.css', '../../style.component.scss'],

})

export class NavbarComponent implements OnInit {
    @ViewChild('memoDialogContent') memoDialogContent!: TemplateRef<any>;
    @ViewChild('viewMemoDialogContent') viewMemoDialogContent!: TemplateRef<any>;
    @ViewChild('notificationMenu') notificationMenu: MatMenuTrigger;
    private dialogRef: MatDialogRef<EmailConnectDialogComponent> | null = null;
    private listTitles: any[];
    subs = new Subscription();
    isopenedmodalforemail=false;
    toastId: number | null = null;
    location: Location;
    isMoreMemo:boolean = true;
    isMoreNotifiction:boolean = true;
    isMoreMemoLoader:boolean = true;
    isMoreNotifictionLoader:boolean = true;
    mobile_menu_visible: any = 0;
    showConnect = true;
    private toggleButton: any;
    private sidebarVisible: boolean;
    toastOptions: Partial<IndividualConfig> = {
        timeOut: 3000, // Duration in milliseconds
        positionClass: 'toast-top-right'
      };
    @ViewChild('app-navbar-cmp') button: any;
    loginUser: any;
    allNotificationActivePageNo: number;
    memoNotificationActivePageNo: number;
    recruiterId: number;
    isSuperAdmin = true;
    notificationsList: any = [];
    memosNotificationList: any = [];
    checkemailconnected = {};
    memoCount = 0;
    isShowContent=false
    notificationCount = 0;
    shouldUpdateStatus = localStorage.getItem('shouldUpdateStatus');
    isOngoingCall = false
    audioToggle = true;
    userObject = null;
    entityObj = [
        { key: 'site_visit_list', url: 'screenercalllist' },
        { key: 'prescreen_list', url: 'prescreenercalllist' },
        { key: 'recruiter_list', url: 'recruitercalllist/recruiter' },
        { key: 'patient_profiles', url: '' },
        { key: 'communication_draft', url: 'communication-draft-screen' },
        { key: 'communication_pending', url: 'communication-pendingreview-screen' },
        { key: 'script_draft', url: 'script-draft-screen' },
        { key: 'script_pending', url: 'script-pending-review-screen' },
        { key: 'prescreen_draft', url: 'prescreen-draft-screen' },
        { key: 'prescreen_pending', url: 'prescreen-pending-review-screen' },
        { key: 'phone_distro', url: 'phonescreen', type: 'distro' },
        { key: 'text_distro', url: 'textscreen', type: 'distro' },
        { key: 'email_distro', url: 'emailscreen', type: 'distro' },
        { key: 'phone_individual', url: 'phonescreen', type: 'individual' },
        { key: 'text_individual', url: 'textscreen', type: 'individual' },
        { key: 'email_individual', url: 'emailscreen', type: 'individual' },
        { key: 'chat_list', url: 'chatscreen' },
        { key: 'notes', url: ''}
    ];
    status = 'online';

    constructor(location: Location, private element: ElementRef, private router: Router,
        private socialAuthService: SocialService,    private toastService: ToastrService,
        private spinner: NgxSpinnerService, public routesService: RoutesService, private statusService: StatusService, private studyService: StudyService,
        private NotitificationsService: NotitificationsService, private datePipe: DatePipe, private _PatientService: PatientService,
        public _RecruiterService: RecruiterService,private dialog: MatDialog, private socketService: SocketHandlerService,
        public _global: GlobalfunctionService, public PrescreenList: PrescreenService, private overlayContainer: OverlayContainer,
        public twilioService: TwilioService) {
        this.location = location;
        this.sidebarVisible = false;
        this.userObject = JSON.parse(localStorage.getItem('data'));
        this.status = localStorage.getItem('userStatus') || this.status; 
    }
    Handledialouge(){
        if(this._global.userRoles.role.includes("CommunicationVerifier")){
        this.spinner.show();
        this._global.IsReady = false;
        const getUser = this.NotitificationsService.getUsersList('').subscribe({
          next: (res) => {
            if (res.status) {
              const dialogRef = this.dialog.open(CreatememoComponent, {
                  panelClass:['small-dailog-width'],
                  data: res.data,
                });
                dialogRef.componentInstance.onSubmitAction.subscribe((action: string) => {   
                });
                this.spinner.hide();
                this._global.IsReady = true;
            }else{
              this.toastService.error('', res.message, this.toastOptions);
              this.spinner.hide();
              this._global.IsReady = true;
            }
          },
          error: (err) => {
            this.spinner.hide();
            this._global.IsReady = true;
            this.toastService.error('', err, this.toastOptions);
          }
        });
        this.subs.add(getUser);
        }else{
         this.router.navigate(['./all-memos-screen']);
        }
    }

    handleConnect(){
        if(!this.isopenedmodalforemail){
            this.isopenedmodalforemail = true;
            const dialogRef = this.dialog.open(EmailConnectDialogComponent, {
                    panelClass:['small-dailog-width'],
                    data: {},
                    disableClose: true, 
                });
                dialogRef.componentInstance.onEmailDialogClose.subscribe((action: string) => {
                this.isopenedmodalforemail = false
            });
            if (this.isopenedmodalforemail) {
                this.changeStatus('dnd', 'DND')
            }
        }
    }

    hasCommunicationVerifierRole(): boolean {
        return this._global.userRoles.role.includes("SuperAdmins");
      }
      
    RevokeToken() {
        const storedCallStatus = localStorage.getItem('isOngoingCall');
        this.isOngoingCall = storedCallStatus ? JSON.parse(storedCallStatus) : false;
        if(this.isOngoingCall){
         this.toastService.error('Logout Restricted', 'A call is currently in progress. You cannot log out at this time.', this.toastOptions);
         }else{
        this._RecruiterService.contactCenterFlag.next(false);
        this._RecruiterService.notificationToasterFlag.next(false);
        this._RecruiterService.notificationRowData.next('');
        // localStorage.setItem('userStatus', 'online');
        this.socketService.disconnectSocket("logout");

        caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
        console.log('going for signout');

        if (this.socialAuthService.isSocialLoggedIn()) {
            this.socialAuthService.signOut().catch((err) => {
                console.log('err at signout=' + JSON.stringify(err));
            });
        }
        console.log('signed out');
        this.router.navigate(['./']);
        if (this.subs) this.subs.unsubscribe();

        // Destroy Twilio Service on Logout
        this.twilioService.ngOnDestroy();
     }
        // this._global.socialUser.image
    }
    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;

        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');

                misc.sidebar_mini_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    viewMemo(){
    this.getMemoList()
    }

    replaceCharacterGlobally(text: string, wordToReplace: string, replacementWord: string): string {
        if (typeof text !== 'string') {
          return ''; 
        }
        let regex = new RegExp(wordToReplace, "g");
        let replacedStr = text.replace(regex, replacementWord);
        return replacedStr;
      }

    getMemoList(): void {
        this.spinner.show();
        this._global.IsReady = false;
        const getData = this.NotitificationsService.getMemoList(true).subscribe({
          next: (res) => {
            if (res.status) {
              this.spinner.hide();
              this._global.IsReady = true;
              const dialogRef = this.dialog.open(ViewMemoComponent, {
                panelClass:['small-dailog-width'],
                data: res.data,
              });
              dialogRef.componentInstance.onSubmitAction.subscribe((action: string) => {
              });

            }else{
              this.toastService.error('', res.message, this.toastOptions);
              this.spinner.hide();
              this._global.IsReady = true;
            }
          },
          error: (err) => {
            this.spinner.hide();
            this._global.IsReady = true;
            this.toastService.error('', err, this.toastOptions);
          }
        });
        this.subs.add(getData);
      }
    



    hideSidebar() {
        const body = document.getElementsByTagName('body')[0];
        const sidebar = document.getElementsByClassName('sidebar')[0];

        if (misc.hide_sidebar_active === true) {
            setTimeout(function () {
                body.classList.remove('hide-sidebar');
                misc.hide_sidebar_active = false;
            }, 300);
            setTimeout(function () {
                sidebar.classList.remove('animation');
            }, 600);
            sidebar.classList.add('animation');

        } else {
            setTimeout(function () {
                body.classList.add('hide-sidebar');
                // $('.sidebar').addClass('animation');
                misc.hide_sidebar_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    ngOnInit() {
        this.loginUser = this._global.getLoginUserDetails()
        
        this.socketService.initialization(this.loginUser.email, { id: this.userObject.recruiterid, roles: this._global.userRoles.role});
        this.audioToggle = localStorage.getItem('notificationAudioFlag') ? JSON.parse(localStorage.getItem('notificationAudioFlag')): true;
        this.NotitificationsService.notificationAudioFlag.next(this.audioToggle);
        // this.getNotificationList(true,1,true);
        // this.getNotificationList(false,1,true);
        // TODO: What if internet disconnects / socket disconnects and reconnects?
        const receivedBadgeCountEventSub = this.socketService.receivedBadgeCountEvent().subscribe(data => {
            this.memoCount = data.memo_count;
            this.notificationCount = data.common_count;
            // this.getNotificationList(true,1,true);
            // this.getNotificationList(false,1,true);
        });
        const receivedUnreadCountEventSub = this.socketService.receivedUnreadCountEvent().subscribe(data => {
            this.NotitificationsService.TotalCountNotificationEmail.next(data.email)
            this.NotitificationsService.TotalCountNotificationPhone.next(data.phone)
            this.NotitificationsService.TotalCountNotificationText.next(data.sms)
            this.NotitificationsService.TotalCountNotificationChat.next(data.chat)
        });
        this.subs.add(receivedBadgeCountEventSub);
        this.subs.add(receivedUnreadCountEventSub);

        this.listTitles = ROUTES.filter(listTitle => listTitle);
        this.isSuperAdmin = this._global.userRoles.role.includes('CommunicationVerifier');

        const checkEmailAccounConnected = this.socketService.getConnectedEmailEvent().subscribe(data => {
        if(data.is_connected) {
            const updateStatusForReload= this.shouldUpdateStatus
            if(updateStatusForReload == "true"){

                this.changeStatus('online', 'Online')
                localStorage.setItem('shouldUpdateStatus','false');
            }
            
            if (this.dialogRef) {
                this.dialogRef.close();
                this.isopenedmodalforemail = false
                this.dialogRef = null;
              }
        } else if(!this.isopenedmodalforemail && !this.hasCommunicationVerifierRole()){
             const dialogRef = this.dialog.open(EmailConnectDialogComponent, {
                    panelClass:['small-dailog-width'],
                    data: {checkemailconnected: this.checkemailconnected },
                    disableClose: true, 
                });
                this.isopenedmodalforemail = true
                if (this.isopenedmodalforemail) {
                    this.changeStatus('dnd', 'DND')
                }
                dialogRef.componentInstance.onEmailDialogClose.subscribe((action: string) => {
                    this.isopenedmodalforemail = false
                });
            }
            this.showConnect= data.is_connected
            console.log("data ===>>>>", data);
        });

        /* Let's handle own Status changes from other tabs / browsers */
        const selfStatusUpdates = this.socketService.receivedUserStatusEvent().subscribe(newStatus => {
            console.log(`NavbarComponent ~ selfStatusUpdates ~ newStatus:`, newStatus);
            if (this.status !== newStatus) {
                this.status = newStatus;
                const emitValues = {
                    'DND': 'dnd',
                    'Online': 'online',
                    'Away': 'away',
                    'Offline': 'offline'
                }
                const emitStatus = emitValues[newStatus] ? emitValues[newStatus] : null;
                this.changeStatus(emitStatus, newStatus, false);
            }
        });
        this.subs.add(selfStatusUpdates);

        /* Let's watch the current call - On new incoming call start need to display it */
        const myStatusSub = this._RecruiterService.myStatus.subscribe(newStatus => {
            console.log("🚀 ~ Navbar ~ myStatusSub ~ newStatus:", newStatus)
            if (this.status !== newStatus) {
                const emitValues = {
                    'dnd': 'DND',
                    'online': 'Online',
                    'away': 'Away',
                    'offline': 'Offline',
                }
                const emitStatus = emitValues[newStatus] ? emitValues[newStatus] : null;
                if (emitStatus) {
                    this.changeStatus(newStatus, emitStatus);
                }
            }
        });

        this.subs.add(myStatusSub);
        this.subs.add(checkEmailAccounConnected);

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if (body.classList.contains('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }

        // debugger;
        const siteId = environment.hotjarSiteId;
        const hotjarVersion = 6;
        Hotjar.init(siteId, hotjarVersion);

        this.ExecuteIndetifyApi();
    }

    ngOnDestroy() {
        this.socketService.disconnectSocket();
        if (this.subs) this.subs.unsubscribe();
    }

    onScroll(event: any, status:boolean): void {  // to handle api call , when user scroll upto bottom
        const element = event.target;
        if (element.scrollHeight - element.scrollTop === element.clientHeight  &&  (this.isMoreMemoLoader === false || this.isMoreNotifictionLoader === false)) {
            status ?   this.getNotificationList(status, this.memoNotificationActivePageNo + 1 ) :   this.getNotificationList(status,this.allNotificationActivePageNo + 1);
        
        }
      }
      

    changeStatus(status: string, emitStatus: string, sendEmit = true): void {
        this.status = status;
        localStorage.setItem('userStatus', this.status);
        if (sendEmit)
        this.socketService.emitUserStatusEvent(emitStatus, this._global.getLoginUserDetails());
        this._RecruiterService.myStatus.next(status);
    }

    onMenuOpened(menuClass: string,type:string): void {
        this.overlayContainer.getContainerElement().classList.add(menuClass);
        if(type === 'noti'){          // List api call when all notification modal open 
            this.getNotificationList(false,1,true);
        }else if(type === 'memo'){        // List api call when memo notification modal open 
            this.getNotificationList(true,1,true);
        }
    }

    onMenuClosed(menuClass: string): void {
        this.notificationsList.map((notification:any) => {
              notification.isShowContent = false;
          });
        setTimeout(() => {
            this.overlayContainer.getContainerElement().classList.remove(menuClass);
        }, 300);
    }

    handleShowContent(item: any, index: number, event: Event): void {
        this.notificationsList.forEach((notification, i) => {
          if (i === index) {
            notification.isShowContent = !notification.isShowContent;
          } else {
            notification.isShowContent = false;
          }
        });
        this.stopEventPropagation(event)
      }

    getNotificationList(type: boolean , pageNumber = 1, isDefault = false): void { 
        let params = new HttpParams().set('is_memo', String(type)).set('filter', 'unread').set('PageNo', String(pageNumber));
        if((this.isMoreMemo && type) || (this.isMoreNotifiction && !type) || isDefault){
            !isDefault &&  type ?  this.isMoreMemoLoader =  true: this.isMoreNotifictionLoader =  true   // to set loader true for api call on scroll  
            this.NotitificationsService.getNotificationList(params).subscribe({
                next: (response) => {
                    if (response.status) {
                    if((type && response.pagination && response.pagination.currentPage != this.memoNotificationActivePageNo)||  // to check only next page number get called everytime 
                        (!type && response.pagination && response.pagination.currentPage != this.allNotificationActivePageNo) || isDefault){
                        if (type) { 
                            this.memosNotificationList = !isDefault ?  [...this.memosNotificationList, ...response.data]  : response.data;   // when type is true api call for memo notification list
                            this.isMoreMemoLoader =  false
                            this.memoNotificationActivePageNo = response.pagination.currentPage || 1;
                            this.isMoreMemo =  response.pagination.isMore
                        } else {
                            this.notificationsList =  !isDefault ? [...this.notificationsList, ...response.data]  : response.data;    // when type is false api call for all notification list
                            this.isMoreNotifictionLoader =  false
                            this.allNotificationActivePageNo = response.pagination.currentPage || 1;
                            this.isMoreNotifiction =  response.pagination.isMore;
                
                        }
                      }
                    } else {
                        if (this.toastId !== null) {
                            this.toastService.remove(this.toastId); 
                          }
                        const toastRef = this.toastService.error('', response.message, this.toastOptions);
                        this.isMoreNotifictionLoader =  false
                        this.isMoreMemoLoader =  false
                        this.toastId = toastRef.toastId; 
                    }
                },
                error: (err) => {
                    if (this.toastId !== null) {
                        this.toastService.remove(this.toastId); 
                      }
                      const toastRef =  this.toastService.error('', err.message, this.toastOptions);
                    this.isMoreNotifictionLoader =  false
                    this.isMoreMemoLoader =  false
                    this.toastId = toastRef.toastId; 
                }
            });
          }
       }

    ExecuteIndetifyApi(){
        if(localStorage.data != undefined) {
            const jsonObject = JSON.parse(localStorage.data);
            const user = jsonObject.user;
            const userName = user.name;
            const userEmail = user.email;
            const userImage = user.image;
            const userRole = jsonObject.role.join(',');
            const recruiterId = jsonObject.recruiterid;

            // Setting Recruiter ID as Extension to display
            this.recruiterId = recruiterId;
            
            Hotjar.identify(userEmail, { 
                id: recruiterId,
                name: userName,
                role: userRole
            });
        }
    }

    // function to short number
    shortenNumber(num: number): string {
    if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + 'M';
    } else if (num >= 1000) {
    const thousands = Math.floor(num / 1000);
    if (thousands >= 100) {
        return thousands + 'k+';
    } else {
        return thousands + 'k';
    }
    } else {
    return num.toString();
    }
    }


    onResize() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }
    sidebarOpen() {
        const $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        setTimeout(function () {
            $toggle.classList.add('toggled');
        }, 430);

        const $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');


        if (body.querySelectorAll('.main-panel')) {
            document.getElementsByClassName('main-panel')[0].appendChild($layer);
        } else if (body.classList.contains('off-canvas-sidebar')) {
            document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        }

        setTimeout(function () {
            $layer.classList.add('visible');
        }, 100);

        $layer.onclick = function () { // asign a function
            body.classList.remove('nav-open');
            this.mobile_menu_visible = 0;
            this.sidebarVisible = false;

            $layer.classList.remove('visible');
            setTimeout(function () {
                $layer.remove();
                $toggle.classList.remove('toggled');
            }, 400);
        }.bind(this);

        body.classList.add('nav-open');
        this.mobile_menu_visible = 1;
        this.sidebarVisible = true;
    }
    sidebarClose() {
        const $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        const $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        this.sidebarVisible = false;
        body.classList.remove('nav-open');
        // $('html').removeClass('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
            $layer.remove();
        }

        setTimeout(function () {
            $toggle.classList.remove('toggled');
        }, 400);

        this.mobile_menu_visible = 0;
    }
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    getTitle() {
        const titlee: any = this.location.prepareExternalUrl(this.location.path());
        for (let i = 0; i < this.listTitles.length; i++) {
            if (this.listTitles[i].type === 'link' && this.listTitles[i].path === titlee) {
                return this.listTitles[i].title;
            } else if (this.listTitles[i].type === 'sub') {
                for (let j = 0; j < this.listTitles[i].children.length; j++) {
                    const subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
                    if (subtitle === titlee) {
                        return this.listTitles[i].children[j].title;
                    }
                }
            }
        }
        return 'Dashboard';
    }
    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }
    openCreateMemoDialog() {
        this.dialog.open(this.memoDialogContent, {
          width: '400px'
        });
      }
    
      openViewMemoDialog() {
        this.dialog.open(this.viewMemoDialogContent, {
          width: '400px'
        });
      }
    
      closeDialog() {
        this.dialog.closeAll();
      }

    seeAllNotification(url: string,notifyId?: string): void { // notifyId is optional 
        this.router.navigate([`./${url}`], { queryParams: { Id: notifyId } });
    }

    convertHtmlToText(html: string): string {
        const element = document.createElement('div');
        element.innerHTML = html;
        return element.textContent || element.innerText || '';
    }

    navigateToAllMemo(data: any): void {
        const viewMemoById = this.NotitificationsService.viewMemoById(data.data.id).subscribe(res => {
            const parsedUrl = new URL(window.location.href);
            this.NotitificationsService.refreshMemoGrid.next(parsedUrl.pathname === '/all-memos-screen' ? true : false);
            data['title'] = res.data.title;
            data['content'] = res.data.content;
            data['id'] = data._id;
            data['_id'] = data.data.id;
            data['created_by'] = data.data.created_by;
            this.NotitificationsService.memoIdData.next(data);
            this.seeAllNotification('all-memos-screen', data._id);
            this.toastService.success('','Memo has been marked as read.', this.toastOptions);
        });
        this.subs.add(viewMemoById);
    }


    stopEventPropagation(event: Event) {
        event.stopPropagation();
    }

    replyToChat(item: any): void {
        if (item.type === 'chat') {
            const filteredObj = this.entityObj.find(data => data.key === item.data.entity_name);
            if (filteredObj) {
                this.PrescreenList.selectedNotificationType.next(item.data.entity_name.includes(filteredObj.type) ? filteredObj.type : '');
                this.socketService.emitChatJoinRoomEvent(item.data.entity_name, item.data.entity_id);
                localStorage.setItem('entity_name', item.data.entity_name);
                localStorage.setItem('entity_title', item.data.entity_title);
                localStorage.setItem('entity_id', item.data.entity_id);
                this.PrescreenList.openChatBox.next(true);
                !!filteredObj.url ? this.seeAllNotification(filteredObj.url, item._id) : null;
                // this.notificationMenu.closeMenu();
            }
        } else if (item.communication_type === 'email' || item.communication_type === 'sms') {
            this.statusService.GetStatus();
            this.studyService.GetActiveStudies();
            this.statusService.GetStatusByType(CallListType.Recruiter);

            this._RecruiterService.contactCenterFlag.next(true);
            this._RecruiterService.contactCenterType.next(item.communication_type);
            this._RecruiterService.contactCenterRowData.next(item.patient_obj);
            this._PatientService.SetPatientId(item.patient_obj.Id);
            this._PatientService.SetRowData(item.patient_obj);

            item.communication_type === 'email' ? this.router.navigate(['./emailscreen'], { queryParams: { Id: item._id } }) : this.router.navigate(['./textscreen'], { queryParams: { Id: item._id } });
            // this.notificationMenu.closeMenu();
        }
    }

    markAsRead(data: any): void {
        this.NotitificationsService.NotificationAction(data._id).subscribe({
            next: (response) => {
                if (response.status) {
                    this.spinner.hide();
                    this._global.IsReady = true;
                    this.getNotificationList(false,1,true);
                    if(data.type === 'chat'){
                        this.NotitificationsService.refreshChatGrid.next(true);
                    }
                    if(data.type === 'text'){
                        this.NotitificationsService.refreshTextGrid.next(true);
                    }
                    if(data.type === 'email'){
                        this.NotitificationsService.refreshEmailGrid.next(true);
                    }
                } else {
                    this.spinner.hide();
                    this._global.IsReady = true;
                    this.toastService.error('', response.message, this.toastOptions);
                }
            },
            error: (err) => {
                this.spinner.hide();
                this._global.IsReady = true;
                this.toastService.error('', err.message, this.toastOptions);
            }
        });
    }

    markAllAsRead(type: string): void {
        this.NotitificationsService.markAllAsRead(type).subscribe({
            next: (response) => {
                if (response.status) {
                    this.spinner.hide();
                    this._global.IsReady = true;
                    if(type==='memo'){
                        this.getNotificationList(true,1,true);
                        setTimeout(() => {
                            this.NotitificationsService.refreshMemoGrid.next(true);
                        }, 200);
                       }else{
                        this.getNotificationList(false,1,true);
                        setTimeout(() => {
                            this.NotitificationsService.refreshChatGrid.next(true);
                            this.NotitificationsService.refreshTextGrid.next(true);
                            this.NotitificationsService.refreshEmailGrid.next(true);
                        }, 200);
                       }
                } else {
                    this.spinner.hide();
                    this._global.IsReady = true;
                    this.toastService.error('', response.message, this.toastOptions);
                }
            },
            error: (err) => {
                this.spinner.hide();
                this._global.IsReady = true;
                this.toastService.error('', err.message, this.toastOptions);
            }
        });
    }

    toggleNotificationAudio() {
        this.audioToggle = !this.audioToggle;
        localStorage.setItem('notificationAudioFlag', JSON.stringify(this.audioToggle));
        this.NotitificationsService.notificationAudioFlag.next(this.audioToggle);
    }
}
