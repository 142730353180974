import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';  // Import FormBuilder and Validators
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateService } from '@sharedservices/BackServices/ComTrak/Communication/template.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'app-declinependingreview',
  templateUrl: './declinependingreview.component.html',
  styleUrls: ['./declinependingreview.component.scss']
})
export class DeclinependingreviewComponent implements OnInit {
  public myData: any = [];
  @Output() onSubmitAction: EventEmitter<string> = new EventEmitter<string>();
 reasonFc = new FormControl('')
 showError:boolean=false;
 public fields: Object = { text: 'name', value: '_id' };
 hashTagList = [];
 selectedItems = [];
 @ViewChild('multiselectelement') multiselectelement: MultiSelectComponent;

  constructor(
    public dialogRef: MatDialogRef<DeclinependingreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ScriptList:TemplateService,
    private _Global: GlobalfunctionService,
    private fb: FormBuilder  // Inject FormBuilder
  ) { }

  ngOnInit() {
    this.myData = this.data.rowData;

    if (this.data && this.data.editTag) {
      this.hashTagList = this.data.listOfHashtags;
      this.selectedItems = this.data.script_tag.map(tag => tag._id);
    }
  }

  onNoClick(flag?: any): void {
    this.dialogRef.close(flag);
  }

  handleInput(): void {
    this.showError = false; 
  }

  onSubmit(value: string): void {
    if (this.data.reason || this.data.reasonSlotDecline) {
      if (this.reasonFc.value && this.reasonFc.value.trim() !== '') {
        this.showError = false;
        this.onSubmitAction.emit(this.reasonFc.value);
        this.onNoClick('yes');
      } else {
        this.showError = true;
      }
    } else if (this.data.editTag) {
      if (this.selectedItems.length) {
        const updatedItems = this.selectedItems.map(data => this.hashTagList.find(res => data == res._id) ? this.hashTagList.find(res => data == res._id) : {name: data} )
        this.onNoClick(updatedItems);
      }
    } else {
      this.onSubmitAction.emit(value);
      this.onNoClick('yes');
    }
  }

  onFiltering(event: any): void {
    const items = this.selectedItems.map(item => this.hashTagList.find((res: any) => res._id === item) ? this.hashTagList.find((res: any) => res._id === item) : { name: item });

    if (items.find((data: any) => data.name.toLowerCase().trim() === event.text.toLowerCase().trim())) {
      this.multiselectelement['popupObj'].element.querySelector('.e-content').style.display = 'none';
    } else {
      this.multiselectelement['popupObj'].element.querySelector('.e-content').style.display = 'block';
    }

    const enteredText: string = event.text as string;
    if (enteredText.length > 50) {
      this._Global.showErrorMsg("Text length should not exceed 50 characters");
      event.cancel = true;
    }
  }

  onItemSelect(event) {
    this.selectedItems.push(event.itemData._id);
    this.selectedItems = [...new Set(this.selectedItems)];
  }

  onItemRemove(event){
    this.selectedItems = this.selectedItems.filter(data => data !== event.itemData._id);
  }

}
