import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RecruiterService {

    apiUrl = 'https://dev-recruitment-management-hvhqbre0fbcmbdbz.eastus-01.azurewebsites.net/';
    constructor(
        public http: HttpClient,
        private _Global: GlobalfunctionService
    ) { }

    getRecruiterSite(): Observable<any> {
        return this.http.get(`${this.apiUrl}site/list`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getRecruiterStudy(siteId: number): Observable<any> {
        return this.http.get(`${this.apiUrl}study/list/${siteId}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSlotList(requestBody: any): Observable<any> {
        return this.http.post(`${this.apiUrl}slot/list`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSlotFilterList(requestBody: any): Observable<any> {
        return this.http.post(`${this.apiUrl}slot/getWithinRange`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    deleteSlot(id: number): Observable<any> {
        return this.http.delete(`${this.apiUrl}slot/delete/${id}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    addSlot(requestBody: any): Observable<any> {
        return this.http.post(`${this.apiUrl}slot/create-new`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    editSlot(requestBody: any): Observable<any> {
        return this.http.put(`${this.apiUrl}slot/update`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    copySlot(requestBody: any): Observable<any> {
        return this.http.post(`${this.apiUrl}slot/duplicateslot-create`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    validateSlot(requestBody: any): Observable<any> {
        return this.http.post(`${this.apiUrl}slot/verify/single`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getPieChart(requestBody: any): Observable<any> {
        return this.http.post(`${this.apiUrl}slot/pie-chart`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSlotConflictData(requestBody: any): Observable<any> {
        return this.http.post(`${this.apiUrl}slot/overlapping-details`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSlotRequest(requestBody: any): Observable<any> {
        return this.http.post(`${this.apiUrl}slot/requested-list`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    slotUpdateRequest(requestBody: any): Observable<any> {
        return this.http.put(`${this.apiUrl}slot/requested-slot-update`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getAuditTrailData(requestBody: any): Observable<any> {
        return this.http.post(`${this.apiUrl}slot/get-site-audit-trail`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSiteBasedOnRole(): Observable<any> {
        return this.http.post(`${this.apiUrl}slot/recruiter-site-details`, {}, { headers: this._Global.httpOptions }) as Observable<any>;
    }
}