import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { LoginComponent } from './login/login.component';
import { PatientProfileComponent } from './patient/patientprofile/patientprofile.component';
import { RegisterationComponent } from './Utilties/registeration/registeration.component';
import { PageNotFoundComponent } from './Utilties/page-not-found/page-not-found.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        // component: LoginComponent,
        pathMatch: 'full',
    },
    {
        path: 'register',
        component: RegisterationComponent,
        pathMatch: 'full',
    },

    {
        // path: ':accessToken/:callbackUrl',
        path: 'login',
        component: LoginComponent,
        // pathMatch: 'full',
    },



    {
        path: '',
        component: AdminLayoutComponent,
        children: [

            {
                path: 'components',
                loadChildren: './components/components.module#ComponentsModule'
            },
            {
                path: 'redirector',
                loadChildren: './redirector/redirector.module#RedirectorModule'
            },
            {
                path: 'forms',
                loadChildren: './forms/forms.module#Forms'
            },
            {
                path: 'tables',
                loadChildren: './tables/tables.module#TablesModule'
            },
            {
                path: 'widgets',
                loadChildren: './widgets/widgets.module#WidgetsModule'
            }, {
                path: 'charts',
                loadChildren: './charts/charts.module#ChartsModule'
            }, {
                path: 'calendar',
                loadChildren: './calendar/calendar.module#CalendarModule'
            }, {
                path: 'timeline',
                loadChildren: './timeline/timeline.module#TimelineModule'
            },
            {
                path: 'powerbi/:id/:navEnabled/:pageNumber/:title',
                loadChildren: './reports/powerbi/powerbi.module#PowerbiModule'
            },
            {
                path: 'operationreports/:reportType/:screenTitle',
                loadChildren: './reports/operationreports/operationreports.module#OperationreportsModule'
            },
            {
                path: 'recruitmentpsreports/:reportType/:screenTitle',
                loadChildren: './reports/operationreports/operationreports.module#OperationreportsModule'
            },
            {
                path: 'recruitercalllist/:screenType',
                loadChildren: './calllists/recruitercalllist/recruitercalllist.module#RecruitercalllistModule'
            },
            {
                path: 'prescreenercalllist',
                loadChildren: './calllists/prescreenercalllist/prescreenercalllist.module#PrescreenercalllistModule'
            },
            {
                path: 'screenercalllist',
                loadChildren: './calllists/screenercalllist/screenercalllist.module#ScreenercalllistModule'
            },
            {
                path: 'patientslist',
                loadChildren: './patient/patientslist/patientslist.module#PatientslistModule'
            },
            {
                path: 'patientprofile/:id',
                //component: PatientProfileComponent,
                 loadChildren: './patient/patientprofile/patientprofile.module#PatientProfileModule'
            },
            {
                path: 'assignpatientstorecruiter',
                loadChildren: './calllists/assignpatienttorecruiter/assignpatienttorecruiter.module#AssignpatienttorecruiterModule'
            },
            // {
            //     path: 'edit-patient/:patientId',
            //     loadChildren: './patient/createpatient/createpatient.module#CreatepatientModule'
            // },
            {
                path: 'Visitcompletedcalllist',
                loadChildren: './calllists/visitcompletedcalllist/visitcompletedcalllist.module#VisitcompletedcalllistModule'
            },
            {
                path: 'homepage',
                loadChildren: './components/homepage/homepage.module#HomepageModule'
            },
            {
                path: 'siteslist',
                loadChildren: './Utilties/siteslist/siteslist.module#SiteslistModule'
            },
            {
                path: 'studieslist',
                loadChildren: './Utilties/studylist/studylist.module#StudylistModule'
            },
            {
                path: 'stafflist',
                loadChildren: './Utilties/stafflist/stafflist.module#StafflistModule'
            },
            {
                path: 'studyvisitlist',
                loadChildren: './Utilties/studyvisitlist/studyvisitlist.module#StudyvisitlistModule'
            },
            {
                path: 'userrolelist',
                loadChildren: './Utilties/userrolelist/userrolelist.module#UserrolelistModule'
            },
            {
                path: 'campaignlist',
                loadChildren: './pages/Campaign/campaignlist/campaignlist.module#CampaignlistModule'
            },
            {
                path: 'campaignreferralsourcelist',
                loadChildren: './pages/Campaign/camapignrefsourcelist/camapignrefsourcelist.module#CamapignrefsourcelistModule'
            },
            {
                path: 'importpatients',
                loadChildren: './patient/importpatient/importpatient.module#ImportpatientModule'
            },
            {
                path: 'sitecalendarlist',
                loadChildren: './Utilties/sitecalendarlist/sitecalendarlist.module#SitecalendarlistModule'
            },
            {
                path: 'scriptcategory',
                loadChildren: './Utilties/scriptcategory/scriptcategory.module#ScriptcategoryModule'
            },
            {
                path: 'manage-ivr-phone',
                loadChildren: './Utilties/ivr-phone/ivr-phone.module#ManageIvrPhoneModule'
            },
            {
                path: 'manage-call-metrics',
                loadChildren: './Utilties/call-metrics/call-metrics.module#ManageCallMetricsModule'
            },
            {
                path: 'department',
                loadChildren: './Utilties/department/department.module#DepartmentModule'
            },
            {
                path: 'prescreencategory',
                loadChildren: './Utilties/prescreencategory/prescreencategory.module#PrescreencategoryModule'
            },
            {
                path: 'visitorflowlist/:VFTypeId/:Title',
                loadChildren: './Utilties/visitorflowlist/visitorflowlist.module#VisitorflowlistModule'
            },
            {
                path: '',
                loadChildren: './role-reassign/role-reassign.module#RoleReassignModule'
            }, {
                path: 'leadslist',
                loadChildren: './Leads/leadslist/leadslist.module#LeadslistModule'
            },
            {
                path: 'leadsvalidation',
                loadChildren: './Leads/leadsvalidation/leadsvalidation.module#LeadsvalidationModule'
            },
            {
                path: 'adminleadlist',
                loadChildren: './Leads/adminleadlist/adminleadlist.module#AdminleadlistModule'
            },
            {
                path: 'discardleads',
                loadChildren: './Leads/discardedleadlist/discardedleadlist.module#DiscardedleadlistModule'
            },
            {
                path: 'leadsreport',
                loadChildren: './Leads/leadsreport/leadsreport.module#LeadsreportModule'
            },
            {
                path: 'poolbuilder/createpool',
                loadChildren: './Leads/createpool/createpool.module#CreatePoolModule'
            },
            {
                path: 'poolbuilder/createpool/:id',
                loadChildren: './Leads/createpool/createpool.module#CreatePoolModule'
            },
            {
                path: 'poolbuilder/patientspoolpublished',
                loadChildren: './Leads/patientspoolpublished/patientspoolpublished.module#PatientsPoolPublishedModule'
            },
            {
                path: 'poolbuilder/patientspooldrafts',
                loadChildren: './Leads/patientspooldrafts/patientspooldrafts.module#PatientsPoolDraftsModule'
            },
            {
                path: 'templatelist',
                loadChildren: './Communication/templatelist/templatelist.module#TemplatelistModule'
            },
            {
                path: 'incomingmessageslist',
                loadChildren: './Communication/incomingmessagelist/incomingmessagelist.module#IncomingmessagelistModule'
            },
            {
                path: 'smsresponselist',
                loadChildren: './Communication/smsresponselist/smsresponselist.module#SmsresponselistModule'
            },
            {
                path: 'outgoingmessagelist',
                loadChildren: './Communication/outgoingmessageslist/outgoingmessageslist.module#OutgoingmessageslistModule'
            },
            {
                path: 'email-accounts',
                loadChildren: './Communication/emailaccounts/emailaccounts.module#EmailAccountsModule'
            },
            {
                path: 'distro-accounts',
                loadChildren: './Communication/distroaccounts/distroaccounts.module#DistroAccountsModule'
            },
            {
                path: 'automated-notifications-screen',
                loadChildren: './automatednotifications/automatednotificationscreen/automatednotificationscreen.module#AutomatedNotificationModule'
            },
            {
                path: 'editor1-screen',
                loadChildren: './editor1/editor1/editor1.module#Editor1Module'
            },
            {
                path: 'editor2-screen',
                loadChildren: './editor1/editor2/editor2.module#Editor2Module'
            },
            {
                path: 'emailscreen',
                loadChildren: './Notifications/email/emailscreen.module#EmailscreenModule'
            },
            {
                path: 'textscreen',
                loadChildren: './Notifications/text/textscreen.module#TextscreenModule'
            },
            {
                path: 'phonescreen',
                loadChildren: './Notifications/phone/phonescreen.module#PhonescreenModule'
            },
            {
                path: 'chatscreen',
                loadChildren: './Notifications/chat/chatscreen.module#ChatscreenModule'
            },
            {
                path: 'all-memos-screen',
                loadChildren: './Notifications/allmemos/all-memos.module#AllMemosModule'
            },
            {
                path: 'prescreen-create-new',
                loadChildren: './Communication/prescreenbuilder/create-new/prescreen-create-new.module#PrescreencreatenewModule'
            },
            {
                path: 'prescreen-discarded-screen',
                loadChildren: './Communication/prescreenbuilder/discardedscreen/prescreendiscardedscreen.module#PrescreenDiscardedscreeenModule'
            },
            {
                path: 'prescreen-draft-screen',
                loadChildren: './Communication/prescreenbuilder/draftscreen/prescreendraftscreen.module#PrescreenDraftscreenModule'
            },
            {
                path: 'prescreen-pending-review-screen',
                loadChildren: './Communication/prescreenbuilder/pendingreviewscreen/prescreenpendingreviewscreen.module#PrescreenPendingReviewScreenModule'
            },
            {
                path: 'prescreen-published-screen',
                loadChildren: './Communication/prescreenbuilder/publishedscreen/prescreenpublishedscreen.module#PrescreenPublishedscreenModule'
            },
            {
                path: 'payment-disbursement',
                loadChildren: './patient/patientpaymentform/patientpaymentform.module#PatientPaymentFormModule'
            },
            {
                path: 'payment-history',
                loadChildren: './patient/patientpaymentlist/patientpaymentlist.module#PatientPaymentListModule'
            },
            {
                path: 'payment-management',
                loadChildren: './patient/studypaymentlist/studypaymentlist.module#StudyPaymentListModule'
            },
            {
                path: 'communication-create-new',
                loadChildren: './Communication/masscommunication/create-new/Communication-create-new.module#CommunicationcreatenewModule'
            },
            {
                path: 'communication-discarded-screen',
                loadChildren: './Communication/masscommunication/discardedscreen/communicationdiscardedscreen.module#CommunicationDiscardedscreeenModule'
            },
            {
                path: 'communication-draft-screen',
                loadChildren: './Communication/masscommunication/draftscreen/communicationdraftscreen.module#CommunicationDraftscreenModule'
            },
            {
                path: 'communication-pendingreview-screen',
                loadChildren: './Communication/masscommunication/pendingreviewscreen/communicationpendingreviewscreen.module#CommunicationPendingReviewScreenModule'
            },
            {
                path: 'communication-published-screen',
                loadChildren: './Communication/masscommunication/publishedscreen/communicationpublishedscreen.module#CommunicationPublishedscreenModule'
            },
            {
                path: 'communication-incoming-message-responses',
                loadChildren: './Communication/masscommunication/incomingmessageresponses/incomingmessageresponses.module#IncomingmessageresponsesModule'
            },
            {
                path: 'create-new-script',
                loadChildren: './Communication/scriptlibrary/create-new/create-new.module#createnewModule'
            },
            {
                path: 'script-draft-screen',
                loadChildren: './Communication/scriptlibrary/draftscreen/draftscreen.module#DraftscreenModule'
            },
            {
                path: 'script-pending-review-screen',
                loadChildren: './Communication/scriptlibrary/pendingreviewscreen/pendingreviewscreen.module#PendingReviewScreenModule'
            }, 
            {
                path: 'script-published-screen',
                loadChildren: './Communication/scriptlibrary/publishedscreen/publishedscreen.module#PublishedscreenModule'
            },
            {
                path: 'script-discarded-screen',
                loadChildren: './Communication/scriptlibrary/discardedscreen/discardedscreen.module#DiscardedscreeenModule'
            },
            {
                path: 'mergedprofileleadlist',
                loadChildren: './Leads/mergedprofileleadlist/mergedprofileleadlist.module#MergedprofileleadlistModule'
            },
            {
                path: 'criopatientslist',
                loadChildren: './Utilties/criopatientslist/criopatientslist.module#CriopatientslistModule'
            },
            {
                path: 'dblmlist',
                loadChildren: './DBLM/dblmlist/dblmlist.module#DblmlistModule'
            },
            {
                path: 'therapeuticarea',
                loadChildren: './Utilties/therapeutic-area/therapeutic-area.module#TherapeuticAreaModule'
            },
            {
                path: 'principalinvestigators',
                loadChildren: './Utilties/principal-investigator/principal-investigator.module#PrincipalInvestigatorModule'
            },
            {
                path: 'dnqlist',
                loadChildren: './calllists/dnqlist/dnqlist.module#DnqlistModule'
            },
            {
                path: 'criostudieslist',
                loadChildren: './Utilties/criostudylist/criostudylist.module#CriostudylistModule'
            },
            {
                path: 'criostudyvisitslist',
                loadChildren: './Utilties/criostudyvisitslist/criostudyvisitslist.module#CriostudyvisitslistModule'
            },
            {
                path: 'criointeractionlist',
                loadChildren: './Utilties/criointeractionslist/criointeractionslist.module#CriointeractionslistModule'
            },
            {
                path: 'vaccinelist',
                loadChildren: './Utilties/vaccinelist/vaccinelist.module#VaccinelistModule'
            },
            {
                path: 'tracker',
                loadChildren: './tracker/tracker.module#TrackerModule'
            },
            {
                path: 'duplicateprofile',
                loadChildren: './calllists/duplicate-profile/duplicateprofile.module#DuplicateProfileModule'
            },
            {
                path: 'slot-view',
                loadChildren: './recruiter/slot-management/slotmanagement.module#SlotManagementModule'
            },
            {
                path: 'slot-requests',
                loadChildren: './recruiter/slot-request-list/slotrequestlist.module#SlotRequestListModule'
            },
        ]
    },
    {
        path: 'auth',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: './pages/pages.module#PagesModule',
        }]
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }

];
