import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { DateRangePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-inputs';

@Component({
    selector: 'app-daterangepicker',
    templateUrl: 'daterangepicker.component.html',
    styleUrls: ['daterangepicker.component.scss'],
})
export class DaterangepickerComponent implements OnInit, OnChanges {
    @Output() dateRangeChange = new EventEmitter<{ startDate: Date, endDate: Date }>();
    @Input() dateRangeEnabled: boolean = true;
    @ViewChild('daterangepicker') dateRangePicker: DateRangePickerComponent;
    @ViewChild('formElement') element: any;
    @Input() IsRequired: boolean = false;
    @Input() defaultStartDate: Date;
    @Input() defaultEndDate: Date;
    startDate: Date;
    endDate: Date;
    @Input()
    inputDatetimeFormat = "M/d/yyyy";
    @Input()
    hourStep = 1;
    @Input()
    minuteStep = 15;
    @Input()
    secondStep = 30;
    @Input()
    seconds = true;
    @Input()
    maxDate = null;
    @Input()
    minDate = null;
    @Output() public onChangeDate = new EventEmitter<any>();
    @Input()
    disabled = false;
    @Input() change = false;
    @Input() fromSlotManagment = false;
    public formObject: FormValidator;

    public today: Date = new Date(new Date().toDateString());
    public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
    public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
        - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString());
    public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
    public monthEnd: Date = this.today;
    public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
    public lastEnd: Date = this.today;
    public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
    public yearEnd: Date = this.today;

    constructor(private fb: FormBuilder) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.change ? this.startDate = this.defaultStartDate ? new Date(this.defaultStartDate) : null : null;
        this.change ? this.endDate = this.defaultEndDate ? new Date(this.defaultEndDate) : null : null;
    }

    ngOnInit(): void {
        var currentDate = new Date();
        this.change ? null : this.startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        this.change ? null : this.endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);
        this.dateRangeChange.emit({ startDate: this.startDate, endDate: this.endDate });

        let options: FormValidatorModel = {
            rules: {
                'daterangepicker': {
                    required: [this.IsRequired, ""]
                }
            },
            customPlacement: (inputElement: HTMLElement, errorElement: HTMLElement) => {
                inputElement.parentElement.parentElement.appendChild(errorElement);
            },
            validationComplete: (args: any) => {
                var element = document.getElementsByClassName('e-date-range-wrapper');
                if (args.status === "failure") {
                    element[0].classList.add('whenerror');
                } else {
                    element[0].classList.remove('whenerror');
                }
            }
        };
        this.formObject = new FormValidator('#form-element', options);
    }

    onFocusOut(): void {
        this.formObject.validate("daterangepicker");
    }

    getNextBusinessDay(date: Date): Date {
        const nextDay = new Date(date);
        nextDay.setDate(nextDay.getDate() + 1);
        while (nextDay.getDay() === 0 || nextDay.getDay() === 6) {
            nextDay.setDate(nextDay.getDate() + 1);
        }
        return nextDay;
    }

    onDateRangeChange(event: any): void {
        this.startDate = event.startDate;
        this.endDate = event.endDate;
        this.dateRangeChange.emit({ startDate: this.startDate, endDate: this.endDate });
        if (event.startDate != null && event.endDate != null) {
            this.formObject.validate("daterangepicker");
            if (this.fromSlotManagment) {
                this.dateRangePicker.enabled = false;
            }
        }
    }

    clearDateRange(): void {
        this.startDate = null;
        this.endDate = null;
        this.dateRangePicker.startDate = null;
        this.dateRangePicker.endDate = null;
        if (this.fromSlotManagment) {
            this.dateRangePicker.enabled = true;
        }
    }
}
