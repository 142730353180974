import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs'
import {FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { RecruiterService } from '@sharedservices/BackServices/ComTrak/Communication/recruiter.service';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { DatePipe } from '@angular/common';
import { DeclinependingreviewComponent } from '@app/pages/declinependingreview/declinependingreview.component';
import { differenceInDays } from 'date-fns';

@Component({
    selector: 'app-slotaddition',
    templateUrl: './slotaddition.component.html',
    styleUrls: ['./slotaddition.component.scss'],
})
export class SlotAdditionComponent implements OnInit {

    // slot addition form
    slotForm!: FormGroup;
    format: string = 'M/d/yyyy';
    subs = new Subscription();
    endMinTime: Date | null = null;
    minTime: Date;
    maxTime: Date;
    maxEndTime: Date;
    frequencyList = ['Daily', 'Weekly'];
    weeks: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    toastOptions: Partial<IndividualConfig> = {
      timeOut: 3000,
      positionClass: 'toast-top-right'
    };
    currentDate = new Date();
    currentIndex = -1;
    defaultStartDate = null;
    defaultEndDate = null;
    validateSlotIndex = [];
    addMoreClicked = false;
    dateValidationIndex = [];
    showConflictDetails = false;
    conflictIndex = [];
    overlapSlotsData = [];
    constructor(
        private toastService: ToastrService,
        public _Global: GlobalfunctionService,
        public spinner: NgxSpinnerService,
        private recruiterService: RecruiterService,
        private fb: FormBuilder,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<SlotAdditionComponent>, 
        private cd: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
      this.minTime = new Date();
      this.minTime.setHours(8, 0, 0);
      this.maxTime = new Date();
      this.maxTime.setHours(17, 0, 0);
      this.maxEndTime = new Date();
      this.maxEndTime.setHours(17, 30, 0);
    }

    ngOnInit(): void {
      this.slotForm = this.fb.group({
        rows: this.fb.array([])
      });
      
      this.createRow(this.data.editData);
      if (!this.data.fromEdit || !this.data.editData) {
        this.addFrequencyValidator();
      }

      this.rows.valueChanges.subscribe((v:any) => {
        if (!!v.length) {
          if (this.dateValidationIndex.length || this.validateSlotIndex.length || this.conflictIndex.length) {
            this.dateValidationIndex = [];
            this.validateSlotIndex = [];
            this.conflictIndex = [];
            this.overlapSlotsData = [];
          }
        }
      })
    }

    addFrequencyValidator(): void {
      this.rows.controls.forEach((formGroup: FormGroup) => {
        formGroup.get('frequency').valueChanges.subscribe((value: string) => {
          const daysControl = formGroup.get('days');
          if (value === 'Weekly') {
            daysControl.setValidators([Validators.required]);
          } else {
            daysControl.clearValidators();
          }
          daysControl.updateValueAndValidity();
        });
      });
    }

    get rows(): FormArray {
      return this.slotForm.get('rows') as FormArray;
    }

    createRow(editData?: any): void {
      if (this.currentIndex > 4) {
        this.toastService.info('', 'You can not add more than 6 slots', this.toastOptions);
      } else {
        if (this.currentIndex === -1) {
          this.currentIndex = 0;
        } else {
          this.currentIndex = this.currentIndex + 1;
        }
        const formData = this.fb.group({
          id: [(!!editData && editData.id) ? editData.id : ''],
          study: [(!!editData && editData.studyId) ? editData.studyId : '', [Validators.required]],
          slotStartDate: [(!!editData && editData.date ? !this.data.fromEdit ? '' : new Date(editData.date) : ''), [Validators.required]],
          slotEndDate: [(!!editData && editData.date ? !this.data.fromEdit ? '' : new Date(editData.date) : ''), [Validators.required]],
          startTime: [(!!editData && editData.startTime ? this.parseTime(editData.startTime) : ''), [Validators.required]],
          minEndTime: [''],
          endTime: [(!!editData && editData.endTime ? this.parseTime(editData.endTime) : ''), [Validators.required]],
          seats: [(!!editData && editData.noOfSeats ? editData.noOfSeats : 1), [Validators.required]],
          frequency: [(!!editData && editData.frequency ? editData.frequency : '')],
          hideFrequency: [false],
          days: [[]],
          weeks: [['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']],
          allowOverBooking: [(!!editData && editData.allowOverBooking ? !this.data.fromEdit ? false : editData.allowOverBooking : false)],
          overBooking: [(!!editData && editData.overBookingCount ? editData.overBookingCount : 1)]
        });

        this.rows.push(formData);
        this.rows.controls.forEach((formGroup: FormGroup) => {
          if (!editData || !this.data.fromEdit) {
            formGroup.get('frequency').setValidators([Validators.required]);
            formGroup.get('frequency').updateValueAndValidity();
          }
        });

        if (this.data.editData && this.data.fromEdit) {
          const minDateUTC = new Date(editData.date);
          minDateUTC.setUTCHours(0, 0, 0, 0); // Set time to midnight in UTC
          // Convert UTC date back to local time zone
          const minDate = new Date(
            minDateUTC.getTime() + minDateUTC.getTimezoneOffset() * 60000
          );
          this.defaultStartDate = minDate;

          const maxDateUTC = new Date(editData.date);
          maxDateUTC.setUTCHours(23, 59, 59, 999); // Set time to end of day in UTC
          // Convert UTC date back to local time zone
          const maxDate = new Date(
            maxDateUTC.getTime() + maxDateUTC.getTimezoneOffset() * 60000
          );
          this.defaultEndDate = maxDate;
        }

        this.addMoreClicked = false;
        if (!!this.data.calendarData && this.currentIndex === 0) {
          this.setDateTimeFromCalendar(this.data.calendarData);
        }
        this.scrollToBottomForChatList();
      }
    }

    parseTime(time: string): Date | null {
      if (!time) return null;
  
      const timeParts = time.match(/(\d+):(\d+) (AM|PM)/);
      if (timeParts) {
        let hours = parseInt(timeParts[1], 10);
        const minutes = parseInt(timeParts[2], 10);
        const isPM = timeParts[3] === 'PM';
  
        if (isPM && hours < 12) hours += 12;
        if (!isPM && hours === 12) hours = 0;
  
        const currentDate = new Date();
        currentDate.setHours(hours, minutes, 0, 0);
  
        return currentDate;
      }
      return null;
    }

    setCurrentDateTime(date: any): void {
      let newDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy')
      let selected = this.datePipe.transform(date, 'MM/dd/yyyy');
      if (newDate === selected) {
        let getHour = this.datePipe.transform(date, 'H');
        this.minTime.setHours(Number(getHour.split(' ')[0]), 0, 0);
      } else {
        this.minTime.setHours(8, 0, 0);
      }
    }

    // set default date and time when open dialog from calendar event
    setDateTimeFromCalendar(calendarDate: any): void {
      const date = this.datePipe.transform(calendarDate, 'MM/dd/yyyy');
      this.defaultEndDate = date;
      this.defaultStartDate = date;
      this.rows.at(0).get('slotStartDate').setValue(date);
      this.rows.at(0).get('slotEndDate').setValue(date);
      this.rows.at(0).get('startTime').setValue(calendarDate);
      this.onStartTimeChange(null, 0);
    }

    checkRowValidation(): void {
      if (this.slotForm.valid) {
        this.validatePastDates();
        this.validateSlotIndex = [];
        this.internallyCheckSlots();
        if (!this.dateValidationIndex.length && !this.validateSlotIndex.length) {
          this.slotValidation();
        }
      } else {
        this.addMoreClicked = true;
        this.slotForm.markAsTouched();
      }
    }

    slotValidation(copyFlag = false): void {
      this.spinner.show();
      this._Global.IsReady = false;
      const data = this.rows.at(this.currentIndex).value;
      const payload = {
        siteId: this.data.site.Id,
        studyId: data.study,
        slotStartdate: data.slotStartDate,
        slotEnddate: data.slotEndDate,
        startTime: this.formatTime(data.startTime),
        endTime: this.formatTime(data.endTime),
        noOfSeats: data.seats,
        frequency: data.frequency,
        days: data.days
      };
      const getData = this.recruiterService.validateSlot(payload).subscribe({
        next:(response) => {
          this.spinner.hide();
          this._Global.IsReady = true;
          if (response.status) {
            copyFlag ? this.saveCopySlots() : this.createRow();
          } else {
            this.toastService.error('', response.message, this.toastOptions);
          }
        },
        error:(err) => {
          this.spinner.hide();
          this._Global.IsReady = true;
          this.toastService.error('', err.message, this.toastOptions);
        }
      });
      this.subs.add(getData);
    }

    internallyCheckSlots(): void {
      let data = this.slotForm.getRawValue();
      const updatedSlotsWithDates: any[] = [];
      data.rows.forEach(slot => {
        const startDate = slot.slotStartDate;
        const endDate = slot.slotEndDate;
        if (slot.frequency == 'Daily') {
          const dateRange = this.getDatesBetween(startDate, endDate);
          slot.validDates = dateRange;
        } else {
          const days = slot.days;
          const dateRange = this.getDatesBetween(startDate, endDate, days);
          slot.validDates = dateRange;
        }
        updatedSlotsWithDates.push(slot);
      });
      this.checkForOverlappingSlots(updatedSlotsWithDates);
    }

    getDatesBetween(startDate: string, endDate: string,days?: string[]): string[] {
      const dates: string[] = [];
      let currentDate = new Date(startDate);
      const lastDate = new Date(endDate);
  
      // Map of day names to their corresponding index (0 for Sunday, 1 for Monday, etc.)
      const dayNameToIndex: { [key: string]: number } = {
        "Sunday": 0,
        "Monday": 1,
        "Tuesday": 2,
        "Wednesday": 3,
        "Thursday": 4,
        "Friday": 5,
        "Saturday": 6,
      };
  
      // If days are provided, convert them to their corresponding day indices
      const targetDays = days ? days.map(day => dayNameToIndex[day]) : null;
  
      while (currentDate <= lastDate) {
        if (!targetDays || targetDays.includes(currentDate.getDay())) {
          dates.push(currentDate.toISOString().split('T')[0]); // Format as 'YYYY-MM-DD'
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dates;
    }

    checkForOverlappingSlots(slots: any[]): void {
      for (let i = 0; i < slots.length; i++) {
        const slotA = slots[i];
        const startDateTimeA = this.formatTime(slotA.startTime);
        const endDateTimeA = this.formatTime(slotA.endTime);
        const slotStudyIdA = slotA.study;
    
        for (let j = i + 1; j < slots.length; j++) {
          const slotB = slots[j];
          const startDateTimeB = this.formatTime(slotB.startTime);
          const endDateTimeB = this.formatTime(slotB.endTime);
          const slotStudyIdB = slotB.study;

          // Check for overlap between slotA and slotB on the same date and study ID
          if (Number(slotStudyIdA) === Number(slotStudyIdB)) {
            for (const dateA of slotA.validDates) {
              for (const dateB of slotB.validDates) {
                if (dateA === dateB) {
                  if (startDateTimeA < endDateTimeB && endDateTimeA > startDateTimeB) {
                    if (!this.validateSlotIndex.includes(j)) {
                      this.validateSlotIndex.push(j);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    removeSlot(index: number): void {
      this.validateSlotIndex = [];
      this.rows.removeAt(index);
      this.currentIndex = this.rows.length - 1;
    }

    displaySeatsCount(type: string, index: number): void {
      const seatsControl = this.rows.at(index).get('seats').value;
      if (seatsControl === 1 && type === 'low') {
        return;
      } else {
        let newSeats = type === 'low' ? seatsControl - 1 : seatsControl + 1;
        this.rows.at(index).get('seats').setValue(newSeats);
      }
    }

    displayOverBookingCount(type: string, index: number): void {
      const overBookingControl = this.rows.at(index).get('overBooking').value;
      if (overBookingControl === 1 && type === 'low') {
        return;
      } else {
        let newOverBooking = type === 'low' ? overBookingControl - 1 : overBookingControl + 1;
        this.rows.at(index).get('overBooking').setValue(newOverBooking);
      }
    }

    onDateRangeChange(dateRange: { startDate: Date, endDate: Date }, i: number): void {
      if (dateRange.startDate && dateRange.endDate) {
        let start = this.datePipe.transform(dateRange.startDate, "yyyy-MM-dd");
        let end = this.datePipe.transform(dateRange.endDate, "yyyy-MM-dd");

        this.rows.at(i).get('slotStartDate').setValue(start);
        this.rows.at(i).get('slotEndDate').setValue(end);

        // Calculate date difference
        const dayDifference = differenceInDays(dateRange.endDate, dateRange.startDate) + 1;
        if (dayDifference >= 7) {
          this.rows.at(i).get('weeks').setValue(
            ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
          );
        } else {
          // Get specific days between start and end date
          let current = new Date(dateRange.startDate);
          let selectedDays = [];
          while (current <= dateRange.endDate) {
            selectedDays.push(this.datePipe.transform(current, 'EEEE')); // Get day name
            current.setDate(current.getDate() + 1);
          }
          this.rows.at(i).get('weeks').setValue(selectedDays);
        }
        if (!this.data.fromEdit) {
          this.frequencyChanges(start, end, i);
        }
      } else {
        this.rows.at(i).get('frequency').setValue('Daily');
        this.rows.at(i).get('days').setValue([]);
        this.rows.at(i).get('weeks').setValue([]);
        this.rows.at(i).get('hideFrequency').setValue(true);
      }
    }

    frequencyChanges(dateStart, dateEnd, index: number): void {
      if (dateStart === dateEnd) {
        this.rows.at(index).get('frequency').setValue('Daily');
        this.rows.at(index).get('days').setValue([]);
        this.rows.at(index).get('weeks').setValue([]);
        this.rows.at(index).get('hideFrequency').setValue(true);

        const today = new Date().setHours(0, 0, 0, 0);
        const selectedDate = new Date(dateStart).setHours(0, 0, 0, 0);
        if (today === selectedDate) {
          let currentHour = new Date().getHours();
          let currentMinute = new Date().getMinutes();
          if (currentMinute > 30) {
            currentHour = new Date().getHours() + 1;
          }
          this.minTime = new Date();
          this.minTime.setHours(currentHour, currentMinute > 30 ? 0 : 30, 0);
          if (!this.data.calendarData) {
            this.rows.at(index).get('startTime').reset();
          }
        } else {
          this.minTime = new Date();
          this.minTime.setHours(8, 0, 0);
        }
        this.cd.detectChanges();
      } else {
        this.rows.at(index).get('hideFrequency').setValue(false);
        this.rows.at(index).get('frequency').reset();
        this.rows.at(index).get('days').setValue([]);
        this.cd.detectChanges();
      }
    }

    onFiltering: EmitType<FilteringEventArgs> = (e: FilteringEventArgs, list: any) => {
      let query = new Query();
      let stitedata : any[]= list;
      query = (e.text != "") ? query.where("Name", "Contains", e.text, true) : query;
      e.updateData(stitedata, query);
    };

    close(): void {
      this.dialogRef.close(false);
    }

    scrollToBottomForChatList(): void {
      setTimeout(() => {
        const container = document.querySelector('.slot-container');
        if (container) {
          const rowContainer = container.querySelectorAll(".row-container")
          if (rowContainer.length) {
            rowContainer[rowContainer.length - 1].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
          }
          container.scrollTop = container.scrollHeight;
        }
        this.cd.detectChanges();
      }, 500)
    }

    onStartTimeChange(event: any, index: number): void {
      const startTime = event ? event as Date :
                        this.rows.at(index).get('startTime').value as Date;
      if (startTime) {
        const endTimeControl = this.rows.at(index).get('endTime');
        const minEndTime = new Date(startTime);
        minEndTime.setMinutes(minEndTime.getMinutes() + 30);
        this.rows.at(index).get('minEndTime').setValue(minEndTime);
        if (endTimeControl.value < minEndTime) {
          endTimeControl.reset();
        }
      }
    }

    onKeyDown(event: KeyboardEvent) {
      var keyCode = event.keyCode || event.which;
      if (
        (keyCode >= 48 && keyCode <= 57) || // Numbers 0-9
        (keyCode >= 96 && keyCode <= 105) || // Numpad numbers 0-9
        (event.key === 'A') ||
        (event.key === 'M') ||
        (event.key === 'P') ||
        (event.key === ':') ||
        event.key === '/' || // / (forward slash)
        keyCode === 8 || // Backspace
        keyCode === 46 || // Delete
        keyCode === 37 || // Left arrow
        keyCode === 39 || // Right arrow
        keyCode === 32 || // Space key
        keyCode === 9 // Tab key
      ) {
        return true;
      }
      // Prevent the default action for other keys
      event.preventDefault();
    }

    setRepeatOn(day: string, index: number): void {
      let dayValue = this.rows.at(index).get('days');
      if (!!dayValue.value) {
        if (dayValue.value.includes(day)) {
          dayValue.setValue(dayValue.value.filter(v => v !== day));
        } else {
          dayValue.setValue([...dayValue.value, day]);
        }
      } else {
        dayValue.value.push(day);
      }
    }

    formatTime(timestamp: any): string {
      const formattedTime = typeof timestamp === 'string' ? timestamp : this.datePipe.transform(timestamp, 'hh:mm a');
      return formattedTime ? formattedTime : '';
    }

    validatePastDates(): void {
      let data = this.slotForm.getRawValue();
      this.dateValidationIndex = [];
      data.rows.forEach((slot, i) => {
        const startDate = slot.slotStartDate;
        this.currentDate.setHours(0, 0, 0, 0);
        new Date(startDate).setHours(0, 0, 0, 0);
        if (new Date(startDate) < this.currentDate) {
          this.dateValidationIndex.push(i);
        }
      });
    }

    saveSlots(): void {
      if (this.slotForm.valid) {
        this.validatePastDates();
        this.validateSlotIndex = [];
        this.internallyCheckSlots();
        if (!this.validateSlotIndex.length && !this.dateValidationIndex.length) {
          if (this.slotForm.valid) {
            const dialogRef = this.dialog.open(DeclinependingreviewComponent, {
              panelClass:['small-dailog-width'],
              data: { 
                title: 'Alert!',
                message: "Are you sure you want to save the slot?",
                AcceptBtnText: "Save",
                CancelBtnText: "Cancel"
              },
              disableClose: true
            });
      
            dialogRef.afterClosed().subscribe(v => {
              if (v === 'yes') {
                let data = this.slotForm.getRawValue();
                let payload = [];
          
                data.rows.forEach(v => {
                  let slotObj:any = {};
                  slotObj = {
                    siteId: this.data.site.Id,
                    studyId: v.study,
                    slotStartdate: v.slotStartDate,
                    slotEnddate: v.slotEndDate,
                    startTime: this.formatTime(v.startTime),
                    endTime: this.formatTime(v.endTime),
                    noOfSeats: v.seats,
                    frequency: v.frequency,
                    days: v.days
                  };
                  payload.push(slotObj);
                });
          
                this.spinner.show();
                this._Global.IsReady = false;
                const getData = this.recruiterService.addSlot(payload).subscribe({
                  next:(response) => {
                    this.spinner.hide();
                    this._Global.IsReady = true;
                    if (response.status) {
                      this.dialogRef.close(true);
                      this.toastService.success('', response.message, this.toastOptions);
                    } else {
                      this.toastService.error('', response.message, this.toastOptions);
                      if (response.data && response.data.length) {
                        this.conflictIndex = response.data.reduce((indices, item, index) => {
                          if (item.isOverlap === true) {
                            indices.push(index);
                          }
                          return indices;
                        }, []);
                      }
                    }
                  },
                  error:(err) => {
                    this.spinner.hide();
                    this._Global.IsReady = true;
                    this.toastService.error('', err.message, this.toastOptions);
                  }
                });
                this.subs.add(getData);
              }
            });
          } else {
            this.toastService.error('', 'Please fill all data', this.toastOptions);
          }
        }
      } else {
        this.addMoreClicked = true;
        this.slotForm.markAsTouched();
      }
    }

    checkCopySlotsValidation(): void {
      if (this.slotForm.valid) {
        this.validatePastDates();
        if (!this.dateValidationIndex.length) {
          this.slotValidation(true);
        }
      } else {
        this.addMoreClicked = true;
        this.slotForm.markAsTouched();
      }
    }

    saveCopySlots(): void {
      if (this.slotForm.valid) {
        const dialogRef = this.dialog.open(DeclinependingreviewComponent, {
          panelClass:['small-dailog-width'],
          data: { 
            title: 'Alert!',
            message: "Are you sure you want to save the slot?",
            AcceptBtnText: "Save",
            CancelBtnText: "Cancel"
          },
          disableClose: true
        });
  
        dialogRef.afterClosed().subscribe(v => {
          if (v === 'yes') {
            let data = this.slotForm.getRawValue().rows[0];
            let payload = {
              allowOverBooking: data.allowOverBooking,
              siteId: this.data.site.Id,
              studyId: data.study,
              slotStartdate: data.slotStartDate,
              slotEnddate: data.slotEndDate,
              startTime: this.formatTime(data.startTime),
              endTime: this.formatTime(data.endTime),
              noOfSeats: data.seats,
            };
            data.allowOverBooking ? (payload['overBookingCount'] = data.overBooking) : null;

            this.spinner.show();
            this._Global.IsReady = false;
            const getData = this.recruiterService.copySlot(payload).subscribe({
              next:(response) => {
                this.spinner.hide();
                this._Global.IsReady = true;
                if (response.status) {
                  this.dialogRef.close(true);
                  this.toastService.success('', response.message, this.toastOptions);
                } else {
                  this.toastService.error('', response.message, this.toastOptions);
                }
              },
              error:(err) => {
                this.spinner.hide();
                this._Global.IsReady = true;
                this.toastService.error('', err.message, this.toastOptions);
              }
            });
            this.subs.add(getData);
          }
        });
      } else {
        this.toastService.error('', 'Please fill all data', this.toastOptions);
      }
    }

    editSlots(): void {
      const dialogRef = this.dialog.open(DeclinependingreviewComponent, {
        panelClass:['small-dailog-width'],
        data: { 
          title: 'Alert!',
          message: "Are you sure you want to update the slot?",
          AcceptBtnText: "Update",
          CancelBtnText: "Cancel"
        },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(v => {
        if (v === 'yes') {
          let data = this.slotForm.getRawValue().rows[0];
          let slotObj = {
            id: data.id,
            allowOverBooking: data.allowOverBooking,
            noOfSeats: data.seats
          };

          data.allowOverBooking ? (slotObj['overBookingCount'] = data.overBooking) : null;
          this.spinner.show();
          this._Global.IsReady = false;
          const getData = this.recruiterService.editSlot(slotObj).subscribe({
            next:(response) => {
              this.spinner.hide();
              this._Global.IsReady = true;
              if (response.status) {
                this.dialogRef.close(true);
                this.toastService.success('', response.message, this.toastOptions);
              } else {
                this.toastService.error('', response.message, this.toastOptions);
              }
            },
            error:(err) => {
              this.spinner.hide();
              this._Global.IsReady = true;
              this.toastService.error('', err.message, this.toastOptions);
            }
          });
          this.subs.add(getData);
        }
      });
    }

    openConflictDialog(flag: boolean, index?: number): void {
      this.showConflictDetails = flag;
      if (this.showConflictDetails) {
        this.spinner.show();
        this._Global.IsReady = false;
        let data = this.slotForm.getRawValue().rows[index];
        let payload = {
          siteId: this.data.site.Id,
          studyId: data.study,
          slotStartdate: data.slotStartDate,
          slotEnddate: data.slotEndDate,
          startTime: this.formatTime(data.startTime),
          endTime: this.formatTime(data.endTime),
        };
        const getData = this.recruiterService.getSlotConflictData(payload).subscribe({
          next:(res) => {
            this.spinner.hide();
            this._Global.IsReady = true;
            if (res.status) {
              this.overlapSlotsData = res.data;
              this.openModal();
            } else {
              this.toastService.error('', res.message, this.toastOptions);
            }
          },
          error:(err) => {
            this.spinner.hide();
            this._Global.IsReady = true;
            this.toastService.error('', err.message, this.toastOptions);
          }
        });
        this.subs.add(getData);
      } else {
        this.closeModal();
        this.overlapSlotsData = [];
      }
    }

    openModal(): void {
      // Open the Bootstrap modal
      const modal = document.getElementById('conflictSlotModal');
      if (modal) {
          modal.classList.add('show');
          modal.setAttribute('aria-modal', 'true');
          modal.style.display = 'block';
      }
    }

    closeModal(): void {
      // Close the Bootstrap modal
      const modal = document.getElementById('conflictSlotModal');
      if (modal) {
          modal.classList.remove('show');
          modal.setAttribute('aria-modal', 'false');
          modal.style.display = 'none';
      }
    }

    ngOnDestroy(): void {
      this.subs.unsubscribe();
    }
}