import { LovService } from './../../../Shared/Services/BackServices/ComTrak/Setups/lov.service';
import { StaffService } from './../../../Shared/Services/BackServices/ComTrak/Staff/staff.service';
import { ExcelService } from './../../../Shared/Services/FrontServices/Export/excel.service';
import { ActionType, CallListType, LovStatusMasterID, Params } from './../../../Shared/Models/DMCREnums/DMCREnums';
import { StatusService } from '@sharedservices/BackServices/ComTrak/Setups/status.service';
import { OperationReportService } from './../../../Shared/Services/BackServices/ComTrak/Reports/operationreport.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { PatientInteractionService } from './../../../Shared/Services/BackServices/ComTrak/Setups/patientInteraction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PatientService } from './../../../Shared/Services/BackServices/ComTrak/Setups/patient.service';
import { PrescreenService } from '@sharedservices/BackServices/ComTrak/Communication/prescreen.service';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Pipe, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSidenav } from '@angular/material';
import { RecruitercalllistMarkComponent } from '@app/pages/recruitercalllist-mark/recruitercalllist-mark.component';
import { RecruitercalllistNotesComponent } from '@app/pages/recruitercalllist-notes/recruitercalllist-notes.component';
import { RecruiterService } from '@sharedservices/BackServices/ComTrak/Recruiters/recruiter.service';
import { Patient } from '@sharedmodels/BackModels/ComTrak/Setups/patient';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { PatientInteraction } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';
import { PatientInteractionDTO } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';
declare const Popup: any;
import * as jsPDF from 'jspdf';
import { ColDef } from 'ag-grid-community';
import { SiteService } from '@sharedservices/BackServices/ComTrak/Setups/site.service';
import { StudyService } from '@sharedservices/BackServices/ComTrak/Setups/study.service';
import { CreatepatientComponent } from '@app/pages/createpatient/createpatient.component';
import { CustomGridColumns } from '@app/components/datatable/datatable.component';
import { CommandClickEventArgs, CommandModel, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';
import { bottomtotop, fadeInOut } from '@app/animation';
import { CampaignReferralSourceService } from '@sharedservices/BackServices/ComTrak/Setups/campaignreferralsource.service';
import { LocationService } from '@sharedservices/BackServices/ComTrak/Setups/location.service';
import { CreatevaccineComponent } from '@app/pages/createvaccine/createvaccine.component';
import { BehaviorSubject } from 'rxjs';
import { PatientDetailComponent } from '../patientdetail/patientdetail.component';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { Study } from '@sharedmodels/BackModels/ComTrak/Setups/Study';
import { ScriptService } from '@sharedservices/BackServices/ComTrak/Script/script.service';
import { SocketHandlerService } from '@sharedservices/FrontServices/socket-handler.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-recruitercalllist',
  templateUrl: './recruitercalllist.component.html',
  styleUrls: ['./recruitercalllist.component.scss', '../../../assets/comtrak-ui-system-v-0.1/sass/screens/_rightSideBar.scss'],
  animations: [
    fadeInOut, bottomtotop
  ]

})
export class RecruitercalllistComponent implements OnInit, OnDestroy {
  @ViewChild('StartDate') StartDate: any;
  @ViewChild('EndDate') EndDate: any;
  @ViewChild('pdfTable') pdfTable: ElementRef;
  @ViewChild('changeStatus') changeStatus: TemplateRef<any>;
  @ViewChild('notes') notes: TemplateRef<any>;
  @ViewChild('markInteraction') markInteraction: TemplateRef<any>;
  @ViewChild('editPatient') editPatient: TemplateRef<any>;

  screenType: string;
  format: string = 'M/d/yyyy';
  paramsForm: FormGroup
  search: string
  statusId: string = '-1'
  isMoreData:boolean = true;
  pagenumber: number;
  show: boolean = false;
  page: number = 1;
  startDate: any = null;
  endDate: any = null;
  pageSize: number = 20;
  public gridApi: any;
  public gridColumnApi: any;
  collectionSize: number = 0;
  columnDefs: ColDef[]
  public defaultColDef;
  columnDefinition: CustomGridColumns[];
  DefaultView: CustomGridColumns[];
  commands: any[];
  showRecord: boolean = false;
  public initial: BehaviorSubject<boolean>
  showPatientDetailDialog = false;
  public patientDetail: RowSelectEventArgs;
  dialogRefs: MatDialogRef<any>[] = [];
  dialogOpenFlag = false;

  studyList: Study[];
  subscriptions = new Subscription();
  public toolbarOptions: object[] =  [
    { text: 'Default View', prefixIcon: 'e-grid-dview', tooltipText: 'Default View', id: 'defaultview' },
    { text: 'Reset Ordering', prefixIcon: 'e-list-reorder', tooltipText: 'Reset Ordering', id: 'resetordering' },
    { text: 'Column Chooser', prefixIcon: 'e-column-chooser', tooltipText: 'Column Chooser', id: 'columnchooser', align: 'left' },
    { text: 'Freeze Columns', prefixIcon: 'e-freeze', tooltipText: 'Freeze Row/Columns', id: 'freezecolumns', align: 'left' },
    { text: 'Create View', prefixIcon: 'e-save-view', tooltipText: 'Save Filter View', id: 'createfilterview' },
    { text: 'Search',align:'right' }
  ];

  constructor(public _RecruiterService: RecruiterService, private fb: FormBuilder, public operationReportService: OperationReportService,
    public dialog: MatDialog, public _PatientService: PatientService, public locationService: LocationService,
    public _studyService: StudyService, public campaignRefSourceService: CampaignReferralSourceService,
    public PrescreenList: PrescreenService, private socketService: SocketHandlerService,
    public _StatusService: StatusService, public _SiteService: SiteService, public _Global: GlobalfunctionService, 
    public spinner: NgxSpinnerService, public _PatientInteractionService: PatientInteractionService, private actRoute: ActivatedRoute,
    private excelService: ExcelService, public staffService: StaffService, public lovService: LovService,
    private renderer: Renderer2, private scriptService: ScriptService, private cd: ChangeDetectorRef) {
  }

  async ngOnInit() {
    const openScriptDialogSub = this.scriptService.openScriptDialog.subscribe(res => {
      this.dialogOpenFlag = res.openFlag;
      res.openFlag ? this.openModal() : this.closeModal();
      setTimeout(() => {
        this._RecruiterService._callListArray = [...this._RecruiterService._callListArray];
      }, 0);
      this.cd.detectChanges();
    });
    this.subscriptions.add(openScriptDialogSub);

    this.initial = new BehaviorSubject(true);
    this.locationService.getAllState()
    this.campaignRefSourceService.LoadCampaignReferralSource()
    await this.getLoadedStudies()
    this._StatusService.GetStatus()
    await this._studyService.GetActiveStudies();
    this._StatusService.GetStatusByType(CallListType.Recruiter);

    //handling studylist, to be replaced with proper obserables
    this.subscriptions.add(
      this._SiteService.sitesStudy.subscribe(
        (studies: Study[]) => this.studyList = studies
      )
    );

    this._SiteService.GetActiveSites();
    this._SiteService._sitesStudy = this._studyService._activeStudies;

    this.actRoute.paramMap.subscribe((data: any) => {
      this.screenType = data.params.screenType
      this._RecruiterService._callList = []
      if (this.screenType == 'admincalllist') {
        this.commands = [
          { title: 'Add Notes', type: 'Delete', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-file' } },
        ];
        this.initForm()
        this._RecruiterService._callListArray = undefined
        this._RecruiterService.arraylength = 0
        this.collectionSize = 0
        this.recruiterAdminCallListgridConfig()
        this.GetAdminCallListReport(0, 0)
        this.staffService.GetAllActiveStaff()
      }
      else if (this.screenType == 'recruiter') {
        this.commands = [
          { title: 'sub-menu', type: 'None', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-contact-center' } },
          { title: 'Add Mark Interaction', type: 'Edit', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-plus' } },
          { title: 'Edit Mark Interaction.', type: 'Delete', buttonOption: { content: '', cssClass: "trash", iconCss: "e-icons e-hyperlink-edit" } },
          { title: 'Add Notes', type: 'Delete', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-file' } },
          { title: 'Edit Patient', type: 'None', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-edit e-icons' } },
          { title: 'Internal Chat', type: 'Chat', buttonOption: { content: '', cssClass: 'custom-bold', iconCss: 'e-icons e-message' } },
          { title: 'Add Vaccine Detail', type: 'Edit', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-calculated-member' } },
        ];
        this.initForm()
        this._RecruiterService._callListArray = undefined
        this._RecruiterService.arraylength = 0
        this.collectionSize = 0
        this.recruiterCallListgridConfig()
        this.lovService.GetLov(LovStatusMasterID.RecruitmentCalllistStatus)
        this.Search(0, 0);
      }
      else {
        return false;
      }
      // this._PatientInteractionService.isCallApiRecruiter.subscribe(res => {
      //   if (res) {
      //     this.Search(this.pageSize ? this.pageSize : 20, this.pagenumber ? this.pagenumber : 1)
      //   }
      // })

      // this._RecruiterService.isCallApi.subscribe(res => {
      //   if (res) {
      //     this.collectionSize = this._RecruiterService.arraylength
      //     this.refreshData(this.page)
      //   }
      // })
    })
  }

  recruiterCallListgridConfig() {
    this.columnDefinition = [
      {
        header: 'ComTrak ID',
        key: "Id",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Crio ID',
        key: "CrioId",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Patient Name',
        key: "FullName",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Age',
        key: "Age",
        width: 150,
        visible: true,
        type: "string",
      },
      {
        header: 'Phone',
        key: "Phone",
        width: 150,
        visible: true,
        type: "string",
      },
      {
        header: 'State',
        key: "State",
        width: 150,
        visible: true,
        type: "string",
      },
      {
        header: 'No of Days Since Import',
        key: "LeadImportDays",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Email',
        key: "Email",
        width: 150,
        visible: true,
        type: "string",
      },
      {
        header: 'Status',
        key: "Status",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Sub Status',
        key: "SubStatus",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Assignment Date',
        key: "AssignmentDate",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Interaction Date',
        key: "fCreatedOn",
        width: 180,
        visible: true,
        type: "string",
      },


      {
        header: 'Study',
        key: "StudyName",
        width: 180,
        visible: true,
        type: "string",
      },

      {
        header: 'Campaign',
        key: "Campaign",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Referral Source',
        key: "CampaignReferralSource",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Site',
        key: "SiteName",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'List Name',
        key: "listMasterName",
        width: 180,
        visible: true,
        type: "string",
      },
      // {
      //   header: 'Language',
      //   key: "LanguageName",
      //   width: 180,
      //   visible: true,
      //   type: "string",
      // },

      // {
      //   header: 'Assignment Date',
      //   key: "LeadAssignedDate",
      //   width: 180,
      //   visible: true,
      //   type: "string",
      // },

      // {
      //   header: 'Transportation',
      //   key: "TransportationName",
      //   width: 180,
      //   visible: true,
      //   type: "string",
      // },
      // {
      //   header: 'CrioLink',
      //   key: "CrioLink",
      //   width: 180,
      //   visible: false,
      //   type: "string",
      // },
    ];
    this.DefaultView = this.columnDefinition;
    // this.defaultColDef = this._Global.defaultColDef;
    // this.columnDefs = [


    //   {
    //     headerName: 'Edit',
    //     colId: 'markint',
    //     width: 80,
    //     minWidth: 80,
    //     maxWidth: 80,
    //     cellRendererFramework: TemplaterenderComponent,
    //     cellRendererParams: {
    //       ngTemplate: this.editPatient
    //     }
    //   },
    //   {
    //     headerName: 'Notes',
    //     colId: 'notes',
    //     width: 80,
    //     minWidth: 80,
    //     maxWidth: 80,
    //     cellRendererFramework: TemplaterenderComponent,
    //     cellRendererParams: {
    //       ngTemplate: this.notes
    //     }
    //   },
    //   {
    //     headerName: 'Mark Int.',
    //     colId: 'markint',
    //     width: 80,
    //     minWidth: 80,
    //     maxWidth: 80,
    //     cellRendererFramework: TemplaterenderComponent,
    //     cellRendererParams: {
    //       ngTemplate: this.markInteraction
    //     }
    //   },
    //   { headerName: 'CmTrk Id', field: 'Id', sortable: true, filter: true },
    //   {
    //     headerName: 'CRIO Id', field: 'CrioId', sortable: true, filter: true,
    //     cellRenderer: function (params) {
    //       let CrioLink = params.data.CrioLink + params.value
    //       // if (params.data.State == "Texas") {
    //       return `<a href="${CrioLink}"  target="_blank" rel="noopener" >${params.value}</a>`
    //       // }
    //       // else {
    //       //   return `<a href="https://app.clinicalresearch.io/dm-clinical-research/dmcr--chicago/patient/${params.value}"  target="_blank" rel="noopener" >${params.value}</a>`
    //       // }
    //     },
    //   },
    //   {
    //     headerName: 'Pat name', field: 'FullName', sortable: true, filter: true,
    //     minWidth: 280,
    //     maxWidth: 900,
    //   },
    //   { headerName: 'Age', field: 'Age', sortable: true, filter: true },
    //   { headerName: 'Phone', field: 'Phone', sortable: true, filter: true },
    //   { headerName: 'State', field: 'State', sortable: true, filter: true },
    //   { headerName: 'Email', field: 'Email', sortable: true, filter: true },
    //   { headerName: 'Site', field: 'SiteName', sortable: true, filter: true },
    //   { headerName: 'Study', field: 'StudyName', sortable: true, filter: true },
    //   { headerName: 'Campaign', field: 'Campaign', sortable: true, filter: true },
    //   { headerName: 'Referral Source', field: 'CampaignReferralSource', sortable: true, filter: true },
    //   { headerName: 'Status', field: 'Status', sortable: true, filter: true },
    //   { headerName: 'Language', field: 'LanguageName', sortable: true, filter: true },
    //   { headerName: 'Transportation', field: 'TransportationName', sortable: true, filter: true },
    //   {
    //     headerName: 'Call Status', colId: 'markint',
    //     width: 150,
    //     minWidth: 280,
    //     maxWidth: 150,
    //     cellRendererFramework: TemplaterenderComponent,
    //     cellRendererParams: {
    //       ngTemplate: this.changeStatus
    //     }
    //   },
    // ];
  }

  recruiterAdminCallListgridConfig() {
    this.columnDefinition = [
      {
        header: ' ComTrak ID',
        key: "Id",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Crio ID',
        key: "CrioId",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Patient Name',
        key: "FullName",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Age',
        key: "Age",
        width: 150,
        visible: true,
        type: "string",
      },
      {
        header: 'Phone',
        key: "Phone",
        width: 150,
        visible: true,
        type: "string",
      },
      {
        header: 'State',
        key: "State",
        width: 150,
        visible: true,
        type: "string",
      },
      {
        header: 'Email',
        key: "Email",
        width: 150,
        visible: true,
        type: "string",
      },
      {
        header: 'Status',
        key: "Status",
        width: 150,
        visible: true,
        type: "string",
      },
      {
        header: 'Sub Status',
        key: "SubStatus",
        width: 150,
        visible: true,
        type: "string",
      },
      {
        header: 'No of Days Since Import',
        key: "LeadImportDays",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Assignment Date',
        key: "AssignmentDate",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Leads Merged Date',
        key: "LeadMergedDate",
        width: 180,
        visible: true,
        type: "string",
      },
      // {
      //   header: 'Import Date',
      //   key: "LeadImportDate",
      //   width: 180,
      //   visible: true,
      //   type: "string",
      // },      
      {
        header: 'No of Days Since Import',
        key: "LeadImportDays",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Interaction Date',
        key: "fCreatedOn",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Study',
        key: "StudyName",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Campaign',
        key: "Campaign",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Referral Source',
        key: "CampaignReferralSource",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Site',
        key: "SiteName",
        width: 180,
        visible: true,
        type: "string",
      },
      // {
      //   header: 'Status',
      //   key: "Status",
      //   width: 180,
      //   visible: true,
      //   type: "string",
      // },
      {
        header: 'CrioLink',
        key: "CrioLink",
        width: 180,
        visible: false,
        type: "string",
      },
    ]

    this.DefaultView = this.columnDefinition;
    // this.defaultColDef = this._Global.defaultColDef;
    // this.columnDefs = [
    //   {
    //     headerName: 'Notes',
    //     colId: 'notes',
    //     width: 80,
    //     minWidth: 80,
    //     maxWidth: 80,
    //     cellRendererFramework: TemplaterenderComponent,
    //     cellRendererParams: {
    //       ngTemplate: this.notes
    //     }
    //   },
    //   { headerName: 'CmTrk Id', field: 'Id', sortable: true, filter: true },
    //   {
    //     headerName: 'CRIO Id', field: 'CrioId', sortable: true, filter: true,
    //     cellRenderer: function (params) {
    //       let CrioLink = params.data.CrioLink + params.value
    //       return `<a href="${CrioLink}"  target="_blank" rel="noopener" >${params.value}</a>`
    //       // return `<a href="https://app.clinicalresearch.io/dm-clinical-research/tcdd/patient/${params.value}"  target="_blank" rel="noopener" >${params.value}</a>`
    //     },
    //   },
    //   {
    //     headerName: 'Pat name', field: 'FullName', sortable: true, filter: true,
    //     width: 280,
    //     minWidth: 280,
    //     maxWidth: 900,
    //   },
    //   { headerName: 'Age', field: 'Age', sortable: true, filter: true },
    //   { headerName: 'Phone', field: 'Phone', sortable: true, filter: true },
    //   { headerName: 'State', field: 'State', sortable: true, filter: true },
    //   {
    //     headerName: 'Email', field: 'Email', sortable: true, filter: true,
    //     width: 280,
    //     minWidth: 280,
    //     maxWidth: 900,
    //   },
    //   {
    //     headerName: 'Interaction date', field: 'fCreatedOn', sortable: true, filter: true,
    //     width: 280,
    //     minWidth: 280,
    //     maxWidth: 900,
    //   },
    //   {
    //     headerName: 'Site', field: 'SiteName', sortable: true, filter: true,
    //     width: 280,
    //     minWidth: 280,
    //     maxWidth: 900,
    //   },
    //   {
    //     headerName: 'Study', field: 'StudyName', sortable: true, filter: true,
    //     width: 280,
    //     minWidth: 280,
    //     maxWidth: 900,
    //   },
    //   {
    //     headerName: 'Campaign', field: 'Campaign', sortable: true, filter: true,
    //     width: 280,
    //     minWidth: 280,
    //     maxWidth: 900,
    //   },
    //   {
    //     headerName: 'Referral Source', field: 'CampaignReferralSource', sortable: true, filter: true,
    //     width: 280,
    //     minWidth: 280,
    //     maxWidth: 900,
    //   },
    //   {
    //     headerName: 'Status', field: 'Status', sortable: true, filter: true,
    //     width: 280,
    //     minWidth: 280,
    //     maxWidth: 900,
    //   },

    // ];
  }

  initForm() {
    this.paramsForm = this.fb.group({
      recruiterId: ['-1'],
      showScheduled: [false],
      showAll: [false],
      startDate: ['', null],
      endDate: ['', null],
      searchText: [''],
      study: ['-1', null],
      site: ['-1', null],
      status: ['-1', null],
      Substatus: ['-1', null],

    })
  }
  ChangeStartDate($event) {
    this.startDate = $event
    if (this.startDate > this.endDate) {
      this._Global.showErrorMsg("Start Date cannot be greater then End Date ")
      this.paramsForm.controls.startDate.setValue(new Date())
    }
    else {
      return false;
    }
  }

  ChangeEndDate($event) {
    this.endDate = $event
    if (this.endDate < this.startDate) {
      this._Global.showErrorMsg("End Date cannot be less then Start Date ")
      this.paramsForm.controls.endDate.setValue(new Date())
    }
    else {
      return false;
    }
  }

  ChangeSite() {
    if (this.paramsForm.controls.site.value != '-1') {
      this._SiteService.GetStudiesBySite(this.paramsForm.controls.site.value, CallListType.Recruiter)
    } else {
      this._SiteService._sitesStudy = this._studyService._activeStudies;
      this._SiteService.sitesStudy.next([]);
    }
  }
  // ChangeStudy() {
  //   if (this.paramsForm.controls.study.value != '-1') {
  //     this._SiteService.GetSitesByStudy(this.paramsForm.controls.study.value)
  //   }
  // }

  clickSearch() {
    // try {
    //   this.paginator.firstPage();
    // } catch (x) { }
    this.Search(0, 0);
  }

  GetRecruiterCallList() {
    this._RecruiterService.GetRecruiterCallList(0, 0, -1, -1, -1, this._PatientService.startDate, this._PatientService.endDate, this._PatientService.searchText);
  }

  Search(pageSize: number, pageNo: number) {
    const searchString = this.paramsForm.controls.searchText.value;
    let startDate = this.startDate
    let endDate = this.endDate
    this._PatientService.startDate = this.startDate
    this._PatientService.endDate = this.endDate
    this._PatientService.searchText = this.paramsForm.controls.searchText.value;
    if (this.screenType == 'recruiter') {
      this._Global.IsReady = false;
      this.spinner.show()
      this._RecruiterService.GetRecruiterCallList(0, 0, -1, -1, -1, this._PatientService.startDate, this._PatientService.endDate, this._PatientService.searchText);
      return
    }
    else if (this.screenType == 'admincalllist') {
      this.GetAdminCallListReport(Params.PageSize, 1)
      return
    }
    else {
      return;
    }
  }
  OpenNewDialog(row: any) {
    this.spinner.show()
    this._PatientService.GetPatientDetailById(row.Id).subscribe(res => {
      const dialogRef = this.dialog.open(CreatepatientComponent, {
        panelClass: ['dailogwidth', 'comtrak-modal'],
        disableClose: true,
        data: {
          callList: <Patient>res,
          screenType: CallListType.Recruiter
        }
      })
      // .afterClosed().subscribe(resData => {
      //   this._PatientService.isCallApiRecruiter.subscribe(res => {
      //     if (res) {
      //       this.Search(0, 0)
      //     }
      //   })
      // });
      this.spinner.hide()
    }, error => {
      this._Global.handleError(error);
      this.spinner.hide()
    })
    // this.spinner.show()
    // this._PatientService.GetPatientDetailById(row.Id).subscribe(res => {
    //   const dialogRef = this.dialog.open(CreatepatientComponent, {
    //     panelClass: 'dailogwidth',
    //     disableClose: true,
    //     data: {
    //       callList: <Patient>res,
    //       screenType:CallListType.Recruiter
    //     }
    //   }).afterClosed().subscribe(res => {
    //     this._PatientService.isCallApiRecruiter.subscribe(resData => {
    //       if (resData) {
    //         this.Search(0, 0)
    //       }
    //     })

    //   });
    //   this.spinner.hide()
    // }, error => {
    //   this._Global.handleError(error);
    //   this.spinner.hide()
    // })
  }

  OpenMarkDialog(row: Patient, isEdit: boolean) {
    this.spinner.show()
    // debugger
    this._PatientInteractionService.GetInteractions(CallListType.Recruiter, row.Id, row.StatusId, row.LastInteractionId).subscribe(res => {
      const dialogRef = this.dialog.open(RecruitercalllistMarkComponent, {
        panelClass: ['dailogwidth', 'comtrak-modal'],
        disableClose: true,
        data: {
          callList: <PatientInteraction>res,
          screenType: CallListType.Recruiter,
          IsDNQ: false,
          isEdit: isEdit,
          formPreview : true,
          lastInterationStatus: row.Status ? row.Status : "",
          lastInterationStatusId: row.StatusId ? row.StatusId : "",
          rowData: row,
        },
      });
      this.spinner.hide()
    }, err => {
      this._Global.handleError(err);
      this.spinner.hide()
    })

  }
  OpenNotesDialog(row: any, pageNo = 1) {
    let params = new HttpParams()
    .append('PageNo', String(pageNo))

    this.spinner.show()
    forkJoin([this._PatientInteractionService.GetPatientInteractions(row.Id),
    this._PatientInteractionService.GetInteractions(CallListType.PatientNotes, row.Id, row.StatusId, row.LastInteractionId),
    this._PatientService.GeCommunicationHistoryById(row.Id, params)]).subscribe(res => {
      let array = [
        { PatientInteraction: <PatientInteractionDTO>res[0], patientInfo: <Patient>res[1], patientList: res[2] }
      ]
      this.isMoreData =  res[2].pagination.isMore;
      const dialogRef = this.dialog.open(RecruitercalllistNotesComponent, {
        panelClass: ['dailogwidth', 'comtrak-modal'],
        // panelClass: ['dailogwidth', 'animate__animated', 'animate__slideInDown'],

        disableClose: true,
        data: {
          PatientInteraction: array,
          hideAddNotes: true,
          rowData: row,
          isMoreData:this.isMoreData,
        }
      });
      this.spinner.hide()
    }, err => {
      this._Global.handleError(err);
      this.spinner.hide()
    })
  }

  ChangeShowAll() {
    if (this.paramsForm.controls.showAll.value == true) {
      this.paramsForm.controls.showScheduled.setValue(false)
    }
    this.Search(0, 0);
  }

  ChangeshowScheduled() {
    if (this.paramsForm.controls.showScheduled.value == true) {
      this.paramsForm.controls.showAll.setValue(false)
    }
    this.Search(0, 0);
  }
  OnReset() {
    this.initForm();
    this._SiteService.sitesStudy.next([]);
    this.StartDate.dateString = ""
    this.EndDate.dateString = ""
  }

  GetAdminCallListReport(pagesize: number, pageno: number) {
    this._Global.IsReady = false
    this.spinner.show()
    const searchString = this.paramsForm.controls.searchText.value;
    let studyid = this.paramsForm.controls.study.value || '-1';
    let siteid = this.paramsForm.controls.site.value;
    let statusid = this.paramsForm.controls.status.value;
    let startDate = this.paramsForm.get('startDate').value;
    let endDate = this.paramsForm.get('endDate').value;
    this._RecruiterService.GetAdminCalllistReport(
      this.paramsForm.controls.recruiterId.value,
      pagesize, pageno, startDate, endDate, siteid, studyid, statusid, searchString).subscribe((res: any) => {
        this._RecruiterService._callList = res.items ? res.items : res
        this._RecruiterService._callListArray = this._RecruiterService._callList
        this._RecruiterService.arraylength = this._RecruiterService._callList ? this._RecruiterService._callList.length : 0
        this.collectionSize = this._RecruiterService.arraylength
        this._Global.IsReady = true

        this.spinner.hide()
      }, error => {
        this._Global.IsReady = true
        this.spinner.hide()
      })
  }

  ChangeStatus(row) {
    if (this.statusId != '-1') {
      try {
        if (confirm("Are you sure ? You want to change call status.") == true) {
          this._Global.IsReady = false
          this.spinner.show()
          this._PatientInteractionService.AddPatientIntercationChangeStatus(row.Id, row.StatusId, this.statusId).subscribe(res => {
            // this._Global.IsReady = true
            // this.spinner.hide()
            this._Global.showSuccessMsg("Status Change Successfully")
            // this.Search(this.pageSize ? this.pageSize : 20, this.pagenumber ? this.pagenumber : 1)
            this.Search(0, 0)
            this.statusId = '-1'
          }, error => {
            this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
            this._Global.IsReady = true
            this.spinner.hide()
            this.statusId = '-1'
          })
        }
        else {
          this.statusId = '-1'
          this._Global.IsReady = true;
          this.spinner.hide()

        }
      }
      catch (ex) {
        this.statusId = '-1'
      }
    }
  }

  PrintElem(elem) {
    Popup(this.screenType, $(elem).html());
  }

  ExportToExcel() {
    this.excelService.exportAsExcelFile(this._RecruiterService._callListArray, this.screenType);
    // this._Global.IsReady = false
    // this.spinner.show()
    // this._RecruiterService.LoadRecruiterCallList(0, 0, this.paramsForm.controls.showScheduled.value,
    //   this.paramsForm.controls.showAll.value, this.paramsForm.controls.searchText.value).subscribe(data => {
    //     this._Global.IsReady = true;
    //     this.spinner.hide();
    //     this.excelService.exportAsExcelFile(<CallList[]>data, this.screenType);
    //   },
    //     error => {
    //       this._Global.IsReady = true;
    //       this.spinner.hide()
    //       this._Global.handleError(error);
    //     });
    // this.excelService.exportAsExcelFile(this._RecruiterService._callList, this.screenType)
  }

  DownloadAsPDF() {
    try {
      const doc = new jsPDF('p', 'pt', [2800, 2800]);
      const pdfTable = this.pdfTable.nativeElement;
      doc.fromHTML(
        pdfTable, 20, 20// HTML string or DOM elem ref.
      );
      doc.save(this.screenType + new Date().toString() + '.pdf');

      // (<any>window).ga('send', 'event', {
      //   eventCategory: 'click',
      //   eventLabel: 'buttonclick',
      //   eventAction: 'SaveTillStatus',
      // });

    } catch (x) {

    }
  }

  // refreshData($event) {
  //   let currentPage = $event == undefined ? this.page : $event
  //   let pageSize = Number(this.pageSize)
  //   this._RecruiterService._callList = this._RecruiterService._callListArray.map((data, i) => ({
  //     id: i + 1, ...data
  //   })).slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize);
  // }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDatasource(this._RecruiterService._callListArray);
  }

  onPageSizeChanged(event: any) {
    this.gridApi.paginationSetPageSize(Number(event.target.value));
  }

  onBtExport() {
    this.gridApi.exportDataAsCsv();
  }

  destroyFilter() {
    this.columnDefs.forEach(element => {
      this.gridApi.destroyFilter(element.field);
    })
  }

  actionClick(data: any): void {

    if (data.type === 'call' || data.type === 'sms' || data.type === 'email') {
      this._RecruiterService.contactCenterFlag.next(true);
      this._RecruiterService.contactCenterType.next(data.type);
      this._RecruiterService.contactCenterRowData.next(data.rowData);
      this._PatientService.SetPatientId(data.rowData.Id)
      this._PatientService.SetRowData(data.rowData)
    }else if(data.command) {
      this.commandClick({commandColumn: {...data.command,  name: "commandClick"}, rowData: data.rowData, cancel: false,})
    }
  }
  
  commandClick(args: any): void {
    this.showPatientDetailDialog = false;
    if (args.commandColumn != undefined) {
      if (args.commandColumn.buttonOption.iconCss == ActionType.MarkInteraction) {
        this.OpenMarkDialog(args.rowData, false)
      }
      else if (args.commandColumn.buttonOption.iconCss == ActionType.Notes) {
        this.OpenNotesDialog(args.rowData)
      }
      else if (args.commandColumn.buttonOption.iconCss == ActionType.Edit) {
        this.OpenNewDialog(args.rowData)
      }
      else if (args.commandColumn.buttonOption.iconCss == ActionType.EditMarkInt) {
        var rowData: any = args.rowData
        var statusObj = this._StatusService._status.find(o => o.Id == rowData.StatusId)
        if (statusObj && statusObj.Roles && statusObj.Roles.filter(x => x.StatusId === rowData.StatusId && ((x.SubStatusId === (rowData.SubStatusId ? rowData.SubStatusId : null)) || x.SubStatusId === null))[0].IsEdit) 
        { 
          this.OpenMarkDialog(args.rowData, true)
        }
        else {
          this._Global.showErrorMsg("This interaction is not editable")
        }
      }
      else if (args.commandColumn.buttonOption.iconCss == ActionType.CreateVaccine) {
        this.OpenVaccineDialog(args.rowData, 'EditSave')
      } else if (args.commandColumn.buttonOption.iconCss === ActionType.Chat) {
        this.socketService.emitChatJoinRoomEvent('recruiter_list', `recruiter_list_${args.rowData.Id}`);
        localStorage.setItem('entity_name', 'recruiter_list');
        localStorage.setItem('entity_title', args.rowData.FullName);
        localStorage.setItem('entity_id', `recruiter_list_${args.rowData.Id}`);
        this.PrescreenList.openChatBox.next(true);
      }
    }
  }

  late() {
    setTimeout(() => {
      this.showRecord = true
    }, 6000);

  }
  CreateVaccineDialog(row, actionType) {
    // //debugger;
    const dialogRef = this.dialog.open(CreatevaccineComponent, {
      panelClass: ['Createvaccinedailogwidth', 'comtrak-modal'],
      disableClose: true,
      data: {
        callList: row,
        actionType: actionType
      }
    })
  }
  OpenVaccineDialog(row, actionType) {
    // //debugger;
    if (row == undefined) {
      this.CreateVaccineDialog(undefined, actionType)
    }
    else {
      this.CreateVaccineDialog(row, actionType)
    }
  }

  onEventCatch(args: RowSelectEventArgs) {
    if (args.target.className.includes('e-rowcell') && !args.target.firstElementChild.classList.contains('icon-button') && !args.target.lastElementChild.classList.contains('icon-button')) {
      const dialogRef = this.dialog.open(PatientDetailComponent, {
        panelClass: ['rightside-modal', 'comtrak-modal'],
        disableClose: false,
        data: { ...args.data, screenType: CallListType.Recruiter },
      })
      this.dialogRefs.push(dialogRef);
      dialogRef.backdropClick().subscribe(() => {
        this.closeAllDialogs();
      });
    }
  }
  onShowPatientDatailSideDialog(showDialog: boolean) {
    if (showDialog) {
      this.showPatientDetailDialog = true;
    }
  }
  closeAllDialogs() {
    for (const dialogRef of this.dialogRefs) {
      dialogRef.close();
    }
    this.dialogRefs = [];
  }

  onFiltering: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
    let query = new Query();
    let stitedata : any[]= this._SiteService._sitesStudy;
    query = (e.text != "") ? query.where("Name", "Contains", e.text, true) : query;
    e.updateData(stitedata, query);
  };

  async getLoadedStudies(){
    await this._studyService.LoadStudies('');
  }

  openModal() {
    // Open the Bootstrap modal
    const modal = document.getElementById('quickScriptModal');
    if (modal) {
      modal.classList.add('show');
      modal.setAttribute('aria-modal', 'true');
      modal.style.display = 'block';
    }
  }

  closeModal() {
    // Close the Bootstrap modal
    const modal = document.getElementById('quickScriptModal');
    if (modal) {
      modal.classList.remove('show');
      modal.setAttribute('aria-modal', 'false');
      modal.style.display = 'none';
    }
  }

  ngOnDestroy(): void {
    if(this.subscriptions) this.subscriptions.unsubscribe();
}
}
